import { OptionModel } from '../models/option.model';
import { AssignmentLayoutMode } from './assignment-layout-mode';
import { IconType } from './icon-type';

export enum AssignmentDisplayMode {
  ProjectFinancials = 'project-financials', // aka 'Project Financials'
  ProjectPricing = 'project-pricing', // aka 'Revenue Records'
  ProjectPricingV2 = 'project-pricingV2', // aka 'Progress Claims'
  ProjectPricingSummary = 'project-pricing-summary', // aka 'Revenue Statement'
  Tags = 'tags',
  Assignments = 'assignments',
  Assets = 'assets',
  Elements = 'elements',
  Timesheets = 'timesheets', // aka 'Cost Records'
  TimesheetsV2 = 'timesheetsV2', // aka 'Timesheet'
  TimesheetsV3 = 'timesheetsV3',
  AssetPricingSummary = 'asset-pricing-summary',
  Calender = 'calender',
  Table = 'table',
  TableV2 = 'tableV2',
  TableV3 = 'tableV3',
  TableV4 = 'tableV4',
}

export const scheduleOptions: OptionModel[] = [
  {
    id: AssignmentDisplayMode.Calender,
    parentId: AssignmentLayoutMode.schedule,
    label: 'Schedule Cards',
    icon: IconType['calendar-circle-user'],
    isEmptyOption: true,
    disabled: false,
    isDefault: true,
  },
  {
    id: AssignmentDisplayMode.Table,
    parentId: AssignmentLayoutMode.schedule,
    label: 'Schedule Table',
    icon: IconType['calendar-days'],
    isEmptyOption: true,
    disabled: false,
  },
  {
    id: AssignmentDisplayMode.TableV2,
    parentId: AssignmentLayoutMode.schedule,
    label: 'Status',
    icon: IconType['calendar-days'],
    isEmptyOption: true,
    disabled: false,
  },
  {
    id: AssignmentDisplayMode.TableV3,
    parentId: AssignmentLayoutMode.schedule,
    label: 'Forms',
    icon: IconType['calendar-days'],
    isEmptyOption: true,
    disabled: false,
  },
  {
    id: AssignmentDisplayMode.TableV4,
    parentId: AssignmentLayoutMode.schedule,
    label: 'Calculations',
    icon: IconType['calendar-days'],
    isEmptyOption: true,
    disabled: false,
  },
];

export const planBoardOptions: OptionModel[] = [
  {
    id: AssignmentDisplayMode.Calender,
    parentId: AssignmentLayoutMode.planBoard,
    label: 'Plan Board',
    icon: IconType['calendar-circle-user'],
    isEmptyOption: true,
    disabled: false,
    isDefault: true,
  },
  {
    id: AssignmentDisplayMode.Table,
    parentId: AssignmentLayoutMode.planBoard,
    label: 'Plan Board',
    icon: IconType['calendar-days'],
    isEmptyOption: true,
    disabled: false,
  },
];

export const pricingOptions: OptionModel[] = [
  {
    id: AssignmentDisplayMode.Timesheets,
    parentId: AssignmentLayoutMode.pricing,
    label: 'Cost Record',
    icon: IconType['history'],
    disabled: false,
    isDefault: true,
  },
  {
    id: AssignmentDisplayMode.TimesheetsV2,
    parentId: AssignmentLayoutMode.pricing,
    label: 'Timesheet',
    icon: IconType['history'],
    disabled: false,
  },
  {
    id: AssignmentDisplayMode.TimesheetsV3,
    parentId: AssignmentLayoutMode.pricing,
    label: 'TimesheetV2',
    icon: IconType['history'],
    disabled: false,
  },
  {
    id: AssignmentDisplayMode.ProjectPricing,
    parentId: AssignmentLayoutMode.pricing,
    label: 'Revenue Record', // Progress Claims',
    icon: IconType['usd-circle'],
    disabled: false,
    isDefault: true,
  },
  {
    id: AssignmentDisplayMode.ProjectPricingV2,
    parentId: AssignmentLayoutMode.pricing,
    label: 'Progress Claims', // Progress Claims',
    icon: IconType['usd-circle'],
    disabled: false,
  },
  {
    id: AssignmentDisplayMode.ProjectFinancials,
    parentId: AssignmentLayoutMode.pricing,
    label: 'Summary',
    icon: IconType['usd-circle'],
    disabled: false,
  },
];

export const databaseOptions: OptionModel[] = [
  {
    id: AssignmentDisplayMode.Tags,
    parentId: AssignmentLayoutMode.database,
    label: 'Tags',
    icon: IconType['history'],
    disabled: false,
    isDefault: true,
  },
  {
    id: AssignmentDisplayMode.Assignments,
    parentId: AssignmentLayoutMode.database,
    label: 'Assignments',
    icon: IconType['city'],
    disabled: false,
  },
  {
    id: AssignmentDisplayMode.Assets,
    parentId: AssignmentLayoutMode.database,
    label: 'Assets',
    icon: IconType['user-hard-hat'],
    disabled: false,
  },
  {
    id: AssignmentDisplayMode.Elements,
    parentId: AssignmentLayoutMode.database,
    label: 'Elements',
    icon: IconType['hexagon'],
    disabled: false,
  },
];

export const reportOptions: OptionModel[] = [
  {
    id: AssignmentDisplayMode.AssetPricingSummary,
    parentId: AssignmentLayoutMode.report,
    label: 'Cost Statement',
    icon: IconType['chart-line-up'],
    disabled: false,
    isDefault: true,
  },
  {
    id: AssignmentDisplayMode.ProjectPricingSummary,
    parentId: AssignmentLayoutMode.report,
    label: 'Revenue Statement', // 'Contract Summary',
    icon: IconType['chart-line-up'],
    disabled: false,
  },
];
