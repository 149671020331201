import { Component, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppState } from '../../../../store';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { WorkspaceModel } from '../../../../core/models/workspace.model';
import { selectCurrentWorkspace } from '../../../../store/workspaces/workspaces.selectors';
import { AppLayoutActions } from '../../../../store/app-layout';
import { SideBarMode } from '../sidebar/sidebar.component';
import { FilePaths } from '../../../../core/constants/file-paths';
import { NodeTemplateModel } from '../../../../core/models/node-template.model';
import { LayoutPickerComponent } from '../../../../shared/modals/layout-picker/layout-picker.component';
import { NodeType } from '../../../../core/constants/node-type';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssignmentLayoutMode } from '../../../../core/constants/assignment-layout-mode';
import { AssignmentDisplayMode } from '../../../../core/constants/assignment-display-mode';
import { WorkspaceLinksModalComponent } from '../../../../shared/modals/workspace-links-modal/workspace-links-modal.component';
import { ShareLinksComponent } from '../../../../shared/modals/share-links/share-links.component';

@Component({
  selector: 'app-secured-header',
  templateUrl: 'secured-header.component.html',
  styleUrls: ['secured-header.component.scss'],
})
export class SecuredHeaderComponent implements OnInit, OnDestroy {
  protected readonly AssignmentLayoutMode = AssignmentLayoutMode;
  protected readonly AssignmentDisplayMode = AssignmentDisplayMode;
  private readonly subscription = new Subscription();
  readonly filePathConstant: typeof FilePaths = FilePaths;
  public readonly environment = environment;

  public currentWorkspace: WorkspaceModel;

  constructor(private store: Store<AppState>, private readonly modalService: NgbModal) {}

  public ngOnInit() {
    this.subscription.add(
      this.store.pipe(select(selectCurrentWorkspace)).subscribe(workspace => {
        setTimeout(() => {
          this.currentWorkspace = workspace;
        }, 0); // Async timeout to prevent ExpressionChangedAfterItHasBeenCheckedError error
      }),
    );
  }

  public openWorkspaceLinks() {
    const modelRef = this.modalService.open(WorkspaceLinksModalComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'ws-links-modal',
    });
    modelRef.result
      .then((result: NodeTemplateModel[]) => {
        if (result.length === 0) {
          return;
        }
        // api call
      })
      .catch(res => {});
  }

  public openWorkspaceShares() {
    const modelRef = this.modalService.open(ShareLinksComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'ws-links-modal',
    });
    modelRef.result
      .then((result: NodeTemplateModel[]) => {
        if (result.length === 0) {
          return;
        }
        // api call
      })
      .catch(res => {});
  }

  public openMainMenu() {
    this.store.dispatch(AppLayoutActions.openSidebarRight({ mode: SideBarMode.MainMenu }));
  }

  public showHelp(): void {
    //
  }

  changeViewToSchedule(): void {
    this.store.dispatch(AppLayoutActions.openSidebarSubLeft({ mode: SideBarMode.NodeList }));
  }

  changeViewToPlanBoard(): void {
    this.store.dispatch(AppLayoutActions.openSidebarSubLeft({ mode: SideBarMode.NodeList }));
  }

  changeViewToFinancials(): void {
    this.store.dispatch(AppLayoutActions.setSidebarLeft({ collapsed: true }));
    this.store.dispatch(AppLayoutActions.setSidebarSubLeft({ collapsed: true }));
    this.store.dispatch(AppLayoutActions.setSidebarRightCollapsed({ collapsed: true }));
  }

  public goToLayoutSettings(): void {
    const modelRef = this.modalService.open(LayoutPickerComponent, {
      size: 'xl',
      backdrop: 'static',
      windowClass: 'layout-picker-modal',
    });
    modelRef.componentInstance.types = [NodeType.assignment];
    modelRef.componentInstance.minSelection = 1;
    modelRef.componentInstance.maxSelection = 1;
    modelRef.result
      .then((result: NodeTemplateModel[]) => {
        if (result.length === 0) {
          return;
        }
        // api call
      })
      .catch(res => {});
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
