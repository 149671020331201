export enum NoDataTypeIds {
  default,
  costTable,
  revenueTable,
  assetPricingSummaryTable,
  dispatchScheduleTable,
  layoutPickerWorkspaceTemplates,
  layoutPickerLayoutTemplates,
  claimsTableChooseAssignment,
  claimsTableNoRecords,
  ratesTable,
  projectsTable,
  assetsTable,
  tagsTable,
  elementsTable,
  cardsTable,
  layoutPickerNoItemSelected,
  chatComments,
  chatChannels,
  workspaceLinks,
  workspaceDocuments,
  workspaceZedularResources,
  shareLinks,
  selectedShareLink,
  SelectForm,
  NoRecipients,
}

export const noDataOptions: NoData[] = [
  {
    id: NoDataTypeIds.default,
    feedbackTitle: 'Oops! No Records',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please make sure to allocate at least one card.',
    buttonDisplay: false,
  },
  {
    // Schedule Table
    id: NoDataTypeIds.cardsTable,
    feedbackTitle: 'Oops! No assignments scheduled yet.',
    svgIconPath: 'assets/svg/calendar.svg',
    feedbackText: 'Try adding an assignment in the calendar for this date range',
    buttonDisplay: false,
  },
  {
    // Dispatch Table
    id: NoDataTypeIds.dispatchScheduleTable,
    feedbackTitle: 'Oops! No Records',
    svgIconPath: 'assets/svg/empty.svg',
    feedbackText: 'Please make sure to allocate at least one resource.',
    buttonDisplay: false,
  },
  {
    // Cost Record
    id: NoDataTypeIds.costTable,
    feedbackTitle: 'Hmm! Check Date Range',
    svgIconPath: 'assets/svg/datePicker.svg',
    feedbackText: 'There are no assignment cards for this date range.',
    buttonDisplay: false,
    buttonClass: 'btn btn-primary',
    buttonLabel: 'Select assignment',
  },
  {
    // Cost Statement
    id: NoDataTypeIds.assetPricingSummaryTable,
    feedbackTitle: 'Choose an assignment',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please select an assignment to view Cost Statement.',
    buttonDisplay: true,
    buttonClass: 'btn btn-primary',
    buttonLabel: 'Select assignment',
  },
  {
    // Revenue Record
    id: NoDataTypeIds.ratesTable,
    feedbackTitle: 'Hmm! Check Date Range',
    svgIconPath: 'assets/svg/datePicker.svg',
    feedbackText: 'There are no assignment cards for this date range.',
    buttonDisplay: false,
    buttonClass: 'btn btn-primary',
    buttonLabel: 'Select assignment',
  },
  {
    // Revenue Statement
    id: NoDataTypeIds.claimsTableChooseAssignment,
    feedbackTitle: 'Choose an assignment',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please select an assignment to view its Revenue Statement.',
    buttonDisplay: true,
    buttonClass: 'btn btn-primary',
    buttonLabel: 'View Assignment Revenue',
  },
  {
    id: NoDataTypeIds.revenueTable,
    feedbackTitle: 'Hmm! Check Date Range',
    svgIconPath: 'assets/svg/datePicker.svg',
    feedbackText: 'There are no assignment cards for this date range.',
    buttonDisplay: false,
    buttonClass: 'btn btn-primary',
    buttonLabel: 'Select assignment',
  },

  {
    id: NoDataTypeIds.layoutPickerWorkspaceTemplates,
    feedbackTitle: 'No workspace templates found!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please search for another template.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.layoutPickerLayoutTemplates,
    feedbackTitle: 'No layout templates found!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please search for another template.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.claimsTableNoRecords,
    feedbackTitle: 'No records',
    falIcon: 'fa-filter-circle-xmark',
    feedbackText: 'Please select another assignment.',
    buttonDisplay: true,
    buttonClass: 'btn btn-primary',
    buttonLabel: 'Select another Assignment',
  },
  {
    id: NoDataTypeIds.projectsTable,
    feedbackTitle: 'No Projects found!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please try add a new project.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.assetsTable,
    feedbackTitle: 'No Assets found!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please try add a new asset.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.tagsTable,
    feedbackTitle: 'No Tags found!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please try add a new tag.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.elementsTable,
    feedbackTitle: 'No Elements found!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please try add a new element.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.layoutPickerNoItemSelected,
    feedbackTitle: 'No item selected!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please select an item to see the details.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.chatChannels,
    feedbackTitle: 'No channels found!',
    svgIconPath: 'assets/svg/chat.svg',
    feedbackText: 'Please try add a new channel',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.chatComments,
    feedbackTitle: 'No comments yet!',
    svgIconPath: 'assets/svg/chat.svg',
    feedbackText: 'Please try add a new comment.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.workspaceLinks,
    feedbackTitle: 'No links found!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please try add a link from the settings.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.workspaceDocuments,
    feedbackTitle: 'No documents found!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please try add a link from the settings.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.workspaceZedularResources,
    feedbackTitle: 'No resources found!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please try add a link from the settings.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.shareLinks,
    feedbackTitle: 'No shared links found!',
    falIcon: 'fa-link',
    feedbackText: 'Please try share the current view.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.selectedShareLink,
    feedbackTitle: 'Select a shared link',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please select a share link from left panel.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.SelectForm,
    feedbackTitle: 'Select a form',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please select a form from left panel.',
    buttonDisplay: false,
  },
  {
    id: NoDataTypeIds.NoRecipients,
    feedbackTitle: 'No Recipients!',
    svgIconPath: 'assets/svg/choose.svg',
    feedbackText: 'Please select a share link from left panel.',
    buttonDisplay: false,
  },
];

export interface NoData {
  id: number;
  feedbackTitle: string;
  svgIconPath?: string;
  falIcon?: string;
  feedbackText: string;
  buttonDisplay: boolean;
  buttonClass?: string;
  buttonLabel?: string;
}
