<datalist *ngIf="options.list && options.list.length" [id]="elementId">
  <option *ngFor="let opt of options.list" [value]="opt"></option>
</datalist>

<span
  (click)="editable && onClick()"
  [ngClass]="editable ? 'editable z-table__input z-table__input--text' : 'z-table__input:disabled z-table__input.disabled'"
>
{{ !cell.value ? ' No item selected ' : cell.value }}
</span>
