import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { ColorType } from '../../../core/constants/color-type';
import { SizeType } from '../../../core/constants/size-type';
import { IconType } from '../../../core/constants/icon-type';
import { dateFormatFromYYYYMM } from '../../../core/utils/date.util';

@Component({
  selector: '[app-selectable-brick]',
  templateUrl: './selectable-brick.component.html',
  styleUrls: ['./selectable-brick.component.scss'],
})
export class SelectableBrickComponent implements OnInit {
  @Input() id?: any;
  @Input() isChecked: boolean;
  @Input() label: string;
  @Input() color: ColorType;
  @Input() icon: IconType;
  @Input() hideIcon: boolean;
  @Input() sizeType: SizeType;
  @Input() readonly: boolean;
  @Input() flexColumn: boolean;
  @Input() rightColTemplate: TemplateRef<any>;
  @Input() dateFormatIndicator = false;

  formattedLabel: string;

  constructor() {}

  ngOnInit(): void {
    if (this.dateFormatIndicator) this.formattedLabel = dateFormatFromYYYYMM(this.label);
    else this.formattedLabel = this.label;
  }
}
