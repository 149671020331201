<datalist *ngIf="options.list && options.list.length" [id]="elementId">
  <option *ngFor="let opt of options.list" [value]="opt"></option>
</datalist>

<input
  #input
  type="text"
  [id]="elementId"
  [attr.list]="elementId"
  class="z-table__input z-table__input--text"
  [ngClass]="{ 'z-table__input--empty': editable && !cell?.value }"
  [(ngModel)]="cell.value"
  [placeholder]="!cell.value ? ' - ' : ''"
  [disabled]="!editable"
  (focus)="onStartEdit()"
  (blur)="onEndEdit()"
  (keyup.enter)="onEnterKey()"
  (keyup.escape)="onEscapeKey()"
/>
