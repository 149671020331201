import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewEncapsulation,
} from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  TableCell,
  TableColumn,
  TableRow,
  TableColumnMoveEvent,
  TableColumnsSortEvent,
  TableRowMoveEvent,
  TableGroup,
  TableGroupMoveEvent,
  TableNewRowEvent,
  zTableProp,
} from './table.common';
import { TableCellEditEvent, TableEditor } from './table-editors/table-editors.common';
import * as XLSX from 'xlsx';
import { IconType } from '../../core/constants/icon-type';
import { ColorType } from '../../core/constants/color-type';
import { momentDateFormat } from '../pipes/moment-date.pipe';
import { NodeTemplateWidgetModel } from '../../core/models/node-template-widget.model';
import { WidgetUtils } from '../../core/utils/widget.util';
import { WidgetType } from '../../core/constants/widget-type';
import { getFormattedAddress } from '../../core/utils/address.util';

@Component({
  selector: 'app-z-table',
  templateUrl: 'table.component.html',
  styleUrls: ['table.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TableComponent implements OnInit, OnChanges, OnDestroy {
  public readonly PAGINATION_VISIBLE_PAGES = 5;
  public readonly PAGINATION_DEFAULT_PAGE_SIZE = 1000;
  public readonly PAGINATION_PAGE_NUMBER_1 = 1;

  public readonly zTableProp = zTableProp;
  public readonly TableEditor = TableEditor;

  public tableEditable = false;

  @Input()
  public page = 1;

  @Input()
  public pageSize = this.PAGINATION_DEFAULT_PAGE_SIZE;

  @Input()
  public rowMenu: TemplateRef<any>;

  @Input()
  public headerColumns: TableColumn[];

  @Input()
  public bodyRows: TableRow[];

  @Input()
  public footerColumns: TableCell[];

  @Input()
  public emptyRow: TableRow;

  @Input()
  public sortBy: string;

  @Input()
  public groupBy: string;

  @Input()
  public allowNewRow: boolean;

  @Input()
  public hideSearch: boolean;

  @Input()
  public hideStats: boolean;

  @Input()
  public paginationHandler: Function;

  @Input()
  public showAssignmentLockIcon = false;

  @Input()
  public isAllAssignmentsLocked = false;

  @Input()
  public hideEditTable = false;

  @Input()
  public showGeneratePdfAction = false;

  @Input()
  public pdfTableId?: number;

  @Output()
  public search = new EventEmitter<string>();

  @Output()
  public moveColumn = new EventEmitter<TableColumnMoveEvent>();

  @Output()
  public moveGroup = new EventEmitter<TableGroupMoveEvent>();

  @Output()
  public sortColumns = new EventEmitter<TableColumnsSortEvent>();

  @Output()
  public moveRow = new EventEmitter<TableRowMoveEvent>();

  @Output()
  public startEdit = new EventEmitter<TableCellEditEvent>();

  @Output()
  public endEdit = new EventEmitter<TableCellEditEvent>();

  @Output()
  public pageChange = new EventEmitter<number>();

  @Output()
  public lockUnlockAssignmentStatus = new EventEmitter();

  @Output()
  public newRow = new EventEmitter<TableNewRowEvent>();

  @Output()
  public downloadPdf = new EventEmitter<boolean>();

  public currentKeywords: string;
  public currentSortDescending: boolean;
  public currentSortColumns: TableColumn[];

  private initialColumnOrder: string[];
  private currentColumnOrder: string[];
  private hiddenGroupIds: string[];
  private hiddenColumnIds: string[];
  private hiddenRowIds: string[];

  public currentGroupColumn: TableColumn;
  public currentSummariseColumn: TableColumn;
  public groupAbleColumns: TableColumn[];
  public summariseColumns: TableColumn[];
  public currentGroupSortDescending: boolean;
  public bodyGroups: TableGroup[];

  public showTotalsRows: boolean;

  public rowsCount: number;
  public pageCount: number;
  public displayColumnCount = 0;
  public pageList: number[];

  public rowsCollapsed: number;
  public columnsCollapsed: number;
  public groupsCollapsed: number;

  constructor() {
    this.headerColumns = [];
    this.bodyGroups = [];
    this.bodyRows = [];
    this.footerColumns = [];
    this.groupAbleColumns = [];
    this.summariseColumns = [];

    this.currentKeywords = '';
    this.currentSortDescending = false;
    this.currentSortColumns = null;
    this.initialColumnOrder = [];
    this.currentColumnOrder = [];
    this.hiddenGroupIds = [];
    this.hiddenColumnIds = [];
    this.hiddenRowIds = [];

    this.currentGroupSortDescending = false;

    this.rowsCollapsed = 0;
    this.columnsCollapsed = 0;
    this.groupsCollapsed = 0;

    this.page = 1;
    this.pageSize = this.PAGINATION_DEFAULT_PAGE_SIZE;
    this.pageList = [1];
  }

  private castSortableFields(a: any, b: any, func: Function) {
    let idxA = func ? func(a) : a || '';
    let idxB = func ? func(b) : b || '';

    if (isNaN(idxA) === false && isNaN(idxB) === false) {
      idxA = Number(idxA);
      idxB = Number(idxB);
    } else {
      idxA = (String(idxA) || '').toLowerCase();
      idxB = (String(idxB) || '').toLowerCase();
    }

    return { idxA, idxB };
  }

  private updateCollapsedCount() {
    this.groupsCollapsed = this.hiddenGroupIds.length;
    this.columnsCollapsed = this.hiddenColumnIds.length;
    this.displayColumnCount = this.headerColumns?.filter(item => !item?.collapsed)?.length;
    // This is calculated in: applyRowCollapsedState()
    //this.rowsCollapsed = this.hiddenRowIds.length;
  }

  private updatePaginationStats() {
    this.rowsCount = this.bodyRows.reduce((count, r) => {
      return count + (r.collapsed === false ? 1 : 0);
    }, 0);
    const prevPageCount = this.pageCount;
    this.pageCount =
      this.rowsCount > 0 && this.pageSize > 0 ? Math.ceil(this.rowsCount / this.pageSize) : 1;
    if (prevPageCount !== this.pageCount) {
      this.page = 1;
    }

    let startAt = this.page - this.PAGINATION_VISIBLE_PAGES;
    let endAt = this.page + this.PAGINATION_VISIBLE_PAGES;

    if (startAt < 1) {
      startAt = 1;
      endAt = this.PAGINATION_VISIBLE_PAGES * 2;
    }

    if (endAt > this.pageCount) {
      endAt = this.pageCount;
    }

    this.pageList = [];
    for (let i = startAt, l = endAt; i <= l; i++) {
      this.pageList.push(i);
    }
  }

  private prepareRowTotals(rows: TableRow[]): number[] {
    if (this.headerColumns == null || this.headerColumns.length === 0) {
      return [];
    }

    const totalCalculation = (sumTotal: number, value: number): number => {
      if (isNaN(value) === true) {
        return sumTotal || 0;
      }
      return (sumTotal || 0) + (value || 0);
    };

    return this.headerColumns.reduce((totals, col, idx) => {
      if (col.includeTotal !== true) {
        return [...totals, null];
      }

      // A totals row is expected
      this.showTotalsRows = true;

      const totalFn = col.totalCalculation || totalCalculation;
      return [
        ...totals,
        (rows || []).reduce((sum, row) => {
          return totalFn(sum, row.columns[idx].value);
        }, null),
      ];
    }, []);
  }

  private applyRowCollapsedState() {
    if (this.bodyRows == null || this.bodyRows.length === 0) {
      return;
    }

    if (!this.currentKeywords) {
      this.rowsCollapsed = 0;
      this.bodyRows.forEach(row => {
        if (this.groupBy) {
          const colIdx = this.headerColumns.findIndex(c => c.id === this.groupBy);
          const groupLabelFn = this.headerColumns[colIdx].groupLabel;
          const groupName = groupLabelFn
            ? groupLabelFn(row.columns[colIdx].value)
            : row.columns[colIdx].value;
          row.collapsed =
            this.hiddenRowIds.indexOf(row.id) !== -1 ||
            this.hiddenGroupIds.indexOf(groupName) !== -1;
        } else {
          row.collapsed = this.hiddenRowIds.indexOf(row.id) !== -1;
        }

        this.rowsCollapsed += row.collapsed === true ? 1 : 0;
      });
    } else {
      this.bodyRows.forEach(row => {
        const hasMatch = row.columns.some((col, index) => {
          const header = this.headerColumns[index];
          return (
            String(header.groupLabel ? header.groupLabel(col.value) : col.value)
              .toLowerCase()
              .indexOf(this.currentKeywords) >= 0
          );
        });
        // In search mode we ignore row and group collapsed state
        row.collapsed = hasMatch === false;
      });
    }
  }

  private prepareGroups() {
    this.showTotalsRows = false;
    if (!this.bodyRows) {
      this.bodyRows = [];
    }

    if (!this.groupBy) {
      this.groupBy = null;
      this.bodyGroups = [];
      this.hiddenGroupIds = [];
      this.currentGroupColumn = null;
    }

    // Sort rows first to ensure pagination is consistent
    this.bodyRows = this.sortRows(this.bodyRows);

    // Apply the collapsed state before pagination
    this.applyRowCollapsedState();

    const pagedRows = this.bodyRows
      .filter(r => r.collapsed === false)
      .slice((this.page - 1) * this.pageSize, this.page * this.pageSize);

    // Build groups
    if (this.groupBy) {
      const colIdx = this.headerColumns.findIndex(c => c.id === this.groupBy);
      if (colIdx !== -1) {
        this.currentGroupColumn = this.headerColumns[colIdx];

        const groupLabelFn = this.headerColumns[colIdx].groupLabel;

        // Create a Groups hash table
        let groupHashTable = {};
        pagedRows.forEach(row => {
          const groupName = groupLabelFn
            ? groupLabelFn(row.columns[colIdx].value)
            : row.columns[colIdx].value;

          if (groupHashTable[groupName] == null) {
            groupHashTable[groupName] = [];
          }
          groupHashTable[groupName].push(row);
        });

        // Build the groups array
        this.bodyGroups = Object.keys(groupHashTable).map(groupName => {
          return {
            id: groupName,
            rows: groupHashTable[groupName] || [],
            totals: this.prepareRowTotals(groupHashTable[groupName]),
          } as TableGroup;
        });
      } else {
        this.groupBy = null;
        this.bodyGroups = [];
        this.currentGroupColumn = null;
      }
    }

    if (this.bodyGroups == null || this.bodyGroups.length == 0) {
      this.currentGroupColumn = null;
      this.bodyGroups = [
        {
          id: '',
          rows: pagedRows,
          totals: this.prepareRowTotals(this.bodyRows),
        } as TableGroup,
      ];
    }

    // Add empty group and row (if requested)
    if (this.emptyRow) {
      this.emptyRow.id = zTableProp.EmptyRowId;
      this.bodyGroups.push({
        id: zTableProp.EmptyGroupId,
        rows: [this.emptyRow],
        totals: [],
      });
    }

    // if filters by summary column -> show only the totals
    if (this.currentSummariseColumn) {
      this.bodyGroups = this.bodyGroups?.map(item => {
        return {
          ...item,
          rows: [],
        };
      });
    }

    this.updateCollapsedCount();
    this.updatePaginationStats();
  }

  private sortTableColumns(propKey: string) {
    if (
      this.initialColumnOrder &&
      this.initialColumnOrder.length > 0 &&
      this.currentColumnOrder &&
      this.currentColumnOrder.length > 0
    ) {
      this.currentColumnOrder.forEach((id, currentIndex) => {
        const previousIndex = this.initialColumnOrder.findIndex(colId => colId === id);

        switch (propKey) {
          case 'headerColumns':
            moveItemInArray(this.headerColumns, previousIndex, currentIndex);
            break;

          case 'bodyRows':
            for (let i = 0, l = this.bodyRows.length; i < l; i++) {
              moveItemInArray(this.bodyRows[i].columns, previousIndex, currentIndex);
            }
            for (let i = 0, l = this.bodyGroups.length; i < l; i++) {
              moveItemInArray(this.bodyGroups[i].totals, previousIndex, currentIndex);
            }
            break;

          case 'footerColumns':
            moveItemInArray(this.footerColumns, previousIndex, currentIndex);
            break;
        }
      });
    }
  }

  private sortRows(rows: TableRow[]): TableRow[] {
    if (
      this.headerColumns == null ||
      this.headerColumns.length === 0 ||
      this.bodyRows == null ||
      this.bodyRows.length === 0
    ) {
      return [];
    }

    const groupColIdx = this.groupBy ? this.headerColumns.findIndex(c => c.id === this.groupBy) : 0;

    //     console.log("Sorting on", this.currentSortColumns);

    return rows.sort((a, b) => {
      let sortValue = 0;

      // Sort by Group
      if (this.currentGroupColumn != null) {
        const gA = a.columns[groupColIdx];
        const gB = b.columns[groupColIdx];

        // Sort by either a nominated Sort Index, Label or field id
        let idxFn = this.currentGroupColumn.groupIndex || this.currentGroupColumn.groupLabel;

        let { idxA, idxB } = this.castSortableFields(gA.value, gB.value, idxFn);
        if (idxA > idxB) {
          sortValue = this.currentGroupSortDescending ? -1 : 1;
        } else if (idxA < idxB) {
          sortValue = this.currentGroupSortDescending ? 1 : -1;
        } else {
          sortValue = 0;
        }
      }

      if (sortValue !== 0) {
        return sortValue;
      }

      // Sort by column/row
      let valuesA = ''; // this will contain the A value to compare
      let valuesB = ''; // this will contain the B value to compare
      let idxFn = null; // this will hold the first function foung (if any)

      //looping through the current sort columns to get the values to compare
      this.currentSortColumns.forEach((currentSortColumn, index) => {
        const colIdx = currentSortColumn
          ? this.headerColumns.findIndex(c => c.id === currentSortColumn.id)
          : 0;

        const cA = a.columns[colIdx];
        const cB = b.columns[colIdx];
        if (this.currentSortColumns[0].sortValue && !idxFn)
          idxFn = this.currentSortColumns[0].sortValue;
        if (typeof cA.value === 'string') valuesA += cA.value + '|';
        if (typeof cB.value === 'string') valuesB += cB.value + '|';
        if (typeof cA.value === 'number') valuesA += ('' + cA.value).padStart(15, '0') + '|'; //number padding to allow alphanumeric sorting
        if (typeof cB.value === 'number') valuesB += ('' + cB.value).padStart(15, '0') + '|';
      });

      let { idxA, idxB } = this.castSortableFields(valuesA, valuesB, idxFn);
      if (idxA > idxB) {
        sortValue = this.currentSortDescending ? -1 : 1;
      } else if (idxA < idxB) {
        sortValue = this.currentSortDescending ? 1 : -1;
      } else {
        sortValue = 0;
      }

      return sortValue;
    });
  }

  public ngOnInit(): void {
    this.initialColumnOrder = this.headerColumns.map(c => c.id);
    this.currentColumnOrder = this.initialColumnOrder;

    this.hiddenColumnIds = this.headerColumns.filter(c => c.collapsed).map(c => c.id);
    this.hiddenRowIds = this.bodyRows.filter(r => r.collapsed).map(r => r.id);

    if (this.sortBy) {
      this.currentSortColumns = [];
      if (this.sortBy.includes('|')) {
        const sortColumns = this.sortBy.split('|');
        sortColumns.forEach(sortColumn => {
          this.headerColumns.forEach(c => {
            if (c.id === sortColumn) this.currentSortColumns.push(c);
          });
        });
      } else {
        this.currentSortColumns.push(this.headerColumns.find(c => c.id === this.sortBy));
      }
    } else {
      this.currentSortColumns.push(this.headerColumns[0] || null);
    }

    if (this.groupBy) {
      this.prepareGroups();
    }

    if (this.headerColumns?.length) {
      const filterCols: TableColumn[] = this.headerColumns.filter(item => item.groupable);
      this.groupAbleColumns = filterCols;
      this.summariseColumns = filterCols;
    }
    this.updateCollapsedCount();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.headerColumns &&
      changes.headerColumns.previousValue !== changes.headerColumns.currentValue
    ) {
      this.initialColumnOrder = this.headerColumns.map(c => c.id);
      if (this.currentColumnOrder == null || this.currentColumnOrder.length === 0) {
        this.currentColumnOrder = this.initialColumnOrder;
      } else {
        // Insert any new columns that are found
        for (let i = 0, l = this.initialColumnOrder.length; i < l; i++) {
          const col = this.initialColumnOrder[i];
          if (this.currentColumnOrder.includes(col) === false) {
            this.currentColumnOrder.splice(i, 0, col);
          }
        }

        // Remove superfluous columns through Intersection
        this.currentColumnOrder = this.currentColumnOrder.filter(c =>
          this.initialColumnOrder.includes(c),
        );
      }
      if (this.currentSortColumns == null) {
        this.currentSortColumns = [];
        if (this.sortBy) {
          if (this.sortBy.includes('|')) {
            const sortColumns = this.sortBy.split('|');
            sortColumns.forEach(sortColumn => {
              this.headerColumns.forEach(c => {
                if (c.id === sortColumn) this.currentSortColumns.push(c);
              });
            });
          } else {
            this.currentSortColumns.push(this.headerColumns.find(c => c.id === this.sortBy));
          }
        } else {
          this.currentSortColumns.push(this.headerColumns[0] || null);
        }
      }
      this.sortTableColumns('headerColumns');
    }

    if (changes.bodyRows && changes.bodyRows.previousValue !== changes.bodyRows.currentValue) {
      this.sortTableColumns('bodyRows');
    }

    if (
      changes.footerColumns &&
      changes.footerColumns.previousValue !== changes.footerColumns.currentValue
    ) {
      this.sortTableColumns('footerColumns');
    }

    this.prepareGroups();
  }

  public toggleGroupBy(column: TableColumn) {
    if (!column.groupable) {
      return;
    }
    const groupName = column.id;
    this.groupBy = this.groupBy !== groupName ? groupName : null;
    this.hiddenGroupIds = [];
    this.hiddenRowIds = [];
    this.page = 1;
    this.prepareGroups();
  }

  public toggleGSummaryBy(column: TableColumn) {
    this.currentGroupColumn = null;
    this.groupBy = null;

    if (!column) {
      this.unGroup();
      return;
    }

    const groupName = column?.id;
    this.groupBy = this.groupBy !== groupName ? groupName : null;
    this.hiddenGroupIds = [];
    this.hiddenRowIds = [];
    this.page = 1;
    this.prepareGroups();
  }

  public unGroup() {
    this.groupBy = null;
    this.page = 1;
    this.prepareGroups();
  }

  public toggleGroupRows(group: TableGroup) {
    if (group != null) {
      if (this.hiddenGroupIds.indexOf(group.id) !== -1) {
        this.hiddenGroupIds = this.hiddenGroupIds.filter(id => id !== group.id);
        group.collapsed = false;
      } else {
        this.hiddenGroupIds.push(group.id);
        group.collapsed = true;
      }
      this.prepareGroups();
    }
  }

  public showAllGroups() {
    this.hiddenGroupIds = [];
    this.groupsCollapsed = 0;
    this.prepareGroups();
  }

  public toggleColumn(column: TableColumn) {
    if (column != null) {
      if (this.hiddenColumnIds.indexOf(column.id) !== -1) {
        this.hiddenColumnIds = this.hiddenColumnIds.filter(id => id !== column.id);
        column.collapsed = false;
      } else {
        this.hiddenColumnIds.push(column.id);
        column.collapsed = true;
      }
      this.updateCollapsedCount();
    }
  }

  public showAllColumns() {
    this.hiddenColumnIds = [];
    this.columnsCollapsed = 0;
    this.headerColumns.forEach(column => {
      column.collapsed = false;
    });
    this.updateCollapsedCount();
  }

  public showHideColumn(item: TableColumn) {
    item.collapsed = !item.collapsed;
    // update hidden columns array
    this.hiddenColumnIds = this.headerColumns.filter(c => c.collapsed).map(c => c.id);
    this.updateCollapsedCount();
  }

  public setGroupByColumn(item: TableColumn): void {
    // clear active summarise column if there
    if (this.currentSummariseColumn) {
      this.currentGroupColumn = null;
      this.groupBy = null;
      this.currentSummariseColumn = null;
    }
    this.currentGroupColumn = this.currentGroupColumn?.id === item?.id ? null : item;
    setTimeout(() => {
      this.toggleGroupBy(item);
    }, 50);
  }

  public setSummariseByColumn(item: TableColumn): void {
    this.currentSummariseColumn = this.currentSummariseColumn?.id === item?.id ? null : item;
    this.toggleGSummaryBy(this.currentSummariseColumn);
  }

  public toggleRow(row: TableRow) {
    // Disable row toggle when search is active
    if (this.currentKeywords) {
      return;
    }
    if (row != null) {
      if (this.hiddenRowIds.indexOf(row.id) !== -1) {
        this.hiddenRowIds = this.hiddenRowIds.filter(id => id !== row.id);
        row.collapsed = false;
      } else {
        this.hiddenRowIds.push(row.id);
        row.collapsed = true;
      }
      this.prepareGroups();
    }
  }

  public showAllRows() {
    this.hiddenGroupIds = [];
    this.hiddenRowIds = [];
    this.rowsCollapsed = 0;
    this.bodyGroups.forEach(group => {
      group.collapsed = false;
      group.rows.forEach(row => {
        row.collapsed = false;
      });
    });
    this.prepareGroups();
  }

  public toggleSort(column: TableColumn) {
    if (!column.sortable) {
      return;
    }

    if (this.currentSortColumns == null || column != this.currentSortColumns[0]) {
      this.currentSortColumns = [];
      this.currentSortColumns.push(column);
    } else {
      if (this.currentGroupColumn === column) {
        this.currentGroupSortDescending = !this.currentGroupSortDescending;
        this.currentSortDescending = this.currentGroupSortDescending;
      } else {
        this.currentSortDescending = !this.currentSortDescending;
      }
    }

    this.prepareGroups();

    this.sortColumns.emit(
      this.currentSortColumns.map(c => {
        return {
          column: c,
          sortDescending: this.currentSortDescending,
        };
      }) as TableColumnsSortEvent,
    );
  }

  public onMoveGroup(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.bodyGroups, event.previousIndex, event.currentIndex);

    this.moveGroup.emit({
      groupBy: this.groupBy,
      group: event.item.data,
      groupIds: this.bodyGroups.map(group => group.id),
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex,
    } as TableGroupMoveEvent);
  }

  public onMoveColumn(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.headerColumns, event.previousIndex, event.currentIndex);
    if (this.bodyRows != null && this.bodyRows.length > 0) {
      for (let i = 0, l = this.bodyRows.length; i < l; i++) {
        moveItemInArray(this.bodyRows[i].columns, event.previousIndex, event.currentIndex);
      }
    }
    if (this.bodyGroups != null && this.bodyGroups.length > 0) {
      for (let i = 0, l = this.bodyGroups.length; i < l; i++) {
        moveItemInArray(this.bodyGroups[i].totals, event.previousIndex, event.currentIndex);
      }
    }
    moveItemInArray(this.footerColumns, event.previousIndex, event.currentIndex);

    this.currentColumnOrder = this.headerColumns.map(c => c.id);

    this.moveColumn.emit({
      column: event.item.data,
      columnIds: this.currentColumnOrder,
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex,
    } as TableColumnMoveEvent);
  }

  public onMoveRow(event: CdkDragDrop<string[]>, group: TableGroup) {
    moveItemInArray(group.rows, event.previousIndex, event.currentIndex);

    this.moveRow.emit({
      groupBy: this.groupBy,
      group: group,
      row: event.item.data,
      rowIds: group.rows.map(row => row.id),
      previousIndex: event.previousIndex,
      currentIndex: event.currentIndex,
      sortColumns: this.currentSortColumns,
      sortDescending: this.currentSortDescending,
    } as TableRowMoveEvent);
  }

  public onSearch(keywords: string) {
    this.currentKeywords = keywords ? keywords.toLowerCase() : '';
    this.page = 1;
    this.prepareGroups();
    this.search.emit(keywords);
  }

  public onStartEdit(event: TableCellEditEvent) {
    this.startEdit.emit(event);
  }

  public onEndEdit(event: TableCellEditEvent) {
    this.endEdit.emit(event);
  }

  public onNewRow() {
    this.newRow.emit({} as TableNewRowEvent);
  }

  public onColumnContextMenu($event) {
    return false;
  }

  public trackGroup(index: number, group: TableGroup) {
    return group.id;
  }

  public trackColumn(index: number, column: TableColumn) {
    return column.id;
  }

  public trackRow(index: number, row: TableRow) {
    return row.id;
  }

  public gotoPage(page: number) {
    this.page = page;
    if (this.page > this.pageCount) {
      this.page = this.PAGINATION_PAGE_NUMBER_1;
    }
    if (this.paginationHandler != null) {
      this.paginationHandler(this.page, this.pageSize);
    } else {
      this.prepareGroups();
    }
    this.pageChange.emit(this.page);
  }

  public nextPage() {
    this.gotoPage(this.page + 1);
  }

  public prevPage() {
    if (this.page === this.PAGINATION_PAGE_NUMBER_1) {
      //  if current page is 1, do nothing
      return;
    }
    this.gotoPage(this.page - 1);
  }

  public exportToExcel(): void {
    const headers = this.headerColumns?.map(item => item?.label);
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet([
      headers,
      ...this.bodyRows.map((row, rowIndex) =>
        row.columns.map((column, index) => this.getColValue(column, index, rowIndex)),
      ),
    ]);

    const workbook: XLSX.WorkBook = { Sheets: { data: worksheet }, SheetNames: ['data'] };

    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    // create the fileName with current timestamp
    const fileName: string = 'export_' + new Date().getTime();
    this.saveAsExcelFile(excelBuffer, fileName);
  }

  getColValue(column: TableCell, index: number, rowIndex: number): any {
    if (!column?.value) {
      return '';
    }
    const hCol: TableColumn = this.headerColumns[index];
    switch (hCol.editor) {
      case TableEditor.WorkspaceGroup:
      case TableEditor.WorkspaceRate:
        return column?.value?.label;

      case TableEditor.WorkspaceTagPreview:
        return column?.value?.title;

      case TableEditor.Icon:
        return IconType[column?.value];

      case TableEditor.Color:
        let c = ColorType[column?.value];
        // remove text called 'color-'from c and add front '#'
        return c ? c.replace('color-', '#') : '';

      case TableEditor.Select:
        return column?.options?.length
          ? column?.options?.find(option => option.value === column?.value)?.label
          : '';

      case TableEditor.Date:
        const date = momentDateFormat(column?.value, 'fullMediumDate');
        return date;

      case TableEditor.Time:
        const time = momentDateFormat(column?.value, 'timeFull');
        return time;

      case TableEditor.MonthYear:
        console.log('MonthYear case');
        const monthYear = momentDateFormat(column?.value, 'monthYear');
        return column?.value;

      // case TableEditor.MonthYearStringList:
      //   console.log("IN monthyearstringlist")
      //   const monthYearArray = column?.value?.split(',').trim();
      //   console.log(monthYearArray);
      //   const monthYearList = monthYearArray
      //     .map(item => momentDateFormat(item, 'monthYear'))
      //     .join(', ');
      //   return monthYearList;

      case TableEditor.Url:
        return new URL(column?.value);

      case TableEditor.checkbox:
      case TableEditor.AssignmentLock:
        return column?.value ? true : false;

      case TableEditor.RowNumber:
        // [row] = 'row'
        // [index] = 'sortDescending ? group.rows.length - rowIndex : rowIndex + 1';
        // this.currentSortDescending ? rowIndex + 1 : rowIndex + 1;
        // this is not working correctly if we do the sorting
        return rowIndex + 1;

      case TableEditor.AssignmentTag:
        return column?.value.tag?.title;

      case TableEditor.AssignmentTags:
        return column?.value?.length ? column?.value.map(item => item?.tag.title).join(', ') : '';

      case TableEditor.AssignmentTemplates:
        return column?.value?.length
          ? column?.value.map(template => template.title).join(', ')
          : '';

      case TableEditor.AssetProfile:
      case TableEditor.AssignmentAssets:
        return column?.value?.length
          ? column?.value.map(item => item?.reference?.title).join(', ')
          : '';

      case TableEditor.AssignmentCalculation:
        const widget = column?.value as NodeTemplateWidgetModel;
        const result = WidgetUtils.getNormalizedNumericWidgetValue(widget, 1);
        return result;

      case TableEditor.AssignmentAdditionalTableFields:
        const widgetValue = column?.value?.value;
        switch (column?.value?.widget?.widgetType) {
          case WidgetType.date:
            const date = widgetValue ? momentDateFormat(widgetValue, 'fullMediumDate') : '';
            return date;

          case WidgetType.number:
          case WidgetType.textbox:
            return widgetValue ? String(widgetValue) : '';

          case WidgetType.address:
            return widgetValue ? getFormattedAddress(widgetValue) : '';

          case WidgetType.checkbox:
            return widgetValue ? true : false;
        }

      case TableEditor.AssignmentMenu:
        break;

      case TableEditor.Spacer:
        break;

      case TableEditor.FolderPicker:
      case TableEditor.AssignedTemplate:
      case TableEditor.Number:
      case TableEditor.Textarea:
        return column?.value;

      default:
        return column?.value;
    }

    return column?.value;
  }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8',
    });
    const downloadLink: HTMLAnchorElement = document.createElement('a');
    const url: string = window.URL.createObjectURL(data);
    downloadLink.href = url;
    downloadLink.download = fileName + '.xlsx';
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
    window.URL.revokeObjectURL(url);
  }

  public lockUnlockAssignments(): void {
    this.lockUnlockAssignmentStatus.emit();
  }

  public editTable(): void {
    this.tableEditable = !this.tableEditable;
  }

  public downloadAsPdf(): void {
    this.downloadPdf.emit(true);
  }

  trackByFn(index): any {
    return index;
  }

  public ngOnDestroy() {
    //
  }
}
