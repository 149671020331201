<header class="print-header">
  <img class="print-header__logo" src="assets/img/new-logo/Zedulr-ICON-2022-SML.jpg" alt="Zedulr" />

  <img class="print-header__logo" [src]="filePathConstant.appLogo_2" alt="Zedulr" />
</header>

<header class="header secured-header">
  <nav class="secured-header__navbar navbar navbar-light">
    <!--LEFT Column - Logo and workspace switch-->

    <div class="secured-header__left-column">
      <a routerLink="/">
        <app-content-loader [positionAbsolute]="false" [headerLogo]="true" ></app-content-loader>
      </a>
      <ng-container *ngIf="currentWorkspace">
        <app-workspace-switch
          class="header-title__workspace-switch"
          [isPopupSelect]="true"
        ></app-workspace-switch>
      </ng-container>
      
    </div>

    <!--Middle Column - Navigation Menu-->
    <ng-container *ngIf="currentWorkspace">
      <div class="secured-header__middle-column d-none d-lg-block">
        <!-- <app-header-title></app-header-title>  -->
        <div class="radio-inputs">
          <div class="d-inline-block layout-mr-lg">
            <button
              class="btn btn-outline-link"
              [routerLink]="['ws', currentWorkspace?.slug, 'content', 'schedule', 'calender']"
              (click)="changeViewToSchedule()"
            >
              <i class="fal fa-calendar-days d-none d-md-inline-block layout-mr-md"></i>
              Schedule
            </button>
          </div>

          <div class="d-inline-block layout-mr-lg">
            <button
              class="btn btn-outline-link"
              [routerLink]="['ws', currentWorkspace?.slug, 'content', 'plan-board', 'calender']"
              (click)="changeViewToPlanBoard()"
            >
              <i class="fal fa-game-board-simple d-none d-md-inline-block layout-mr-md"></i>
              Plan Board
            </button>
          </div>

          <div class="d-inline-bloc layout-mr-lg">
            <button
              class="btn btn-outline-link"
              [routerLink]="[
                'ws',
                currentWorkspace?.slug,
                'content',
                'pricing',
                'project-financials'
              ]"
              (click)="changeViewToFinancials()"
            >
              <i class="fal fa-sack-dollar d-none d-md-inline-block layout-mr-md"></i>
              Financials
            </button>
          </div>

          <!--  <div ngbDropdown class="d-inline-block layout-mr-lg">
            <button
              class="btn btn-outline-link"
              [routerLink]="['ws', currentWorkspace?.slug, 'content', 'database', 'tags']"
            >
              <i class="fal fa-database d-none d-md-inline-block layout-mr-md"></i>
              Database
            </button>
          </div>-->

          <!--   <div ngbDropdown class="d-inline-block">
            <button class="btn btn-outline-link" disabled>
              <i class="fal fa-chart-gantt d-none d-md-inline-block layout-mr-md"></i>
              Gantt Chart
              <span class="position-relative translate-middle badge rounded-pill badge-warning"
                >Coming Soon</span
              >
            </button>
          </div>-->
        </div>
      </div>

      <!--Right Column - Profiles and icon buttons-->

      <div class="secured-header__right-column">
        <app-workspace-users
          class="header-title__workspace-users mr-2 hide-on-md hide-on-lg"
        ></app-workspace-users>

        <!--Help Icon and txt-->
        <!--<div class="secured-header__help-btn btn nav__button" (click)="showHelp()" id="help-btn">
          <span>
            HELP
          </span>
          <i class="fa-solid fa-circle-question ml-2"></i>
        </div>-->

        <button
          type="button"
          class="nav__button btn fal fa-store ml-4"
          title="Layout Library"
          (click)="goToLayoutSettings()"
          id="layout-btn"
        ></button>

        <app-nav-chat class=""></app-nav-chat>

        <button
          type="button"
          class="nav__button btn fal fa-life-ring"
          title="Resource Center"
          (click)="openWorkspaceLinks()"
        ></button>

        <button
          type="button"
          class="nav__button btn fal fa-share-alt"
          title="Public Share"
          (click)="openWorkspaceShares()"
        ></button>

        <button
          type="button"
          class="nav__button btn fal fa-bars"
          title="Main Menu"
          (click)="openMainMenu()"
        ></button>
      </div>
    </ng-container>

    <div ngbDropdown class="nav-item dropdown-toggle" role="presentation">
      <button
        aria-label="toggle"
        type="button"
        class="nav__button btn fal fa-bars"
        ngbDropdownToggle
      ></button>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
        <button
          ngbDropdownItem
          [routerLink]="['/ws', currentWorkspace?.slug, 'content', 'schedule', 'calender']"
        >
          <i class="fal fa-calendar-days mr-2"></i>Schedule
        </button>
        <button
          ngbDropdownItem
          [routerLink]="['/ws', currentWorkspace?.slug, 'content', 'plan-board', 'calender']"
        >
          <i class="fal fa-game-board-simple mr-2"></i>Plan Board
        </button>
        <button
          ngbDropdownItem
          [routerLink]="['ws', currentWorkspace?.slug, 'content', 'pricing', 'project-financials']"
        >
          <i class="fal fa-coins mr-2"></i>Financials
        </button>
        <button
          ngbDropdownItem
          [routerLink]="['/ws', currentWorkspace?.slug, 'content', 'database', 'tags']"
        >
          <i class="fal fa-database mr-2"></i>Database
        </button>
        <!--   <button ngbDropdownItem>
          <i class="fal fa-chart-gantt mr-2"></i> Gantt Chart
          <span class="position-relative translate-middle badge rounded-pill badge-warning"
            >Coming Soon</span
          >
        </button>-->
      </div>
    </div>
  </nav>
</header>
