import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {select, Store} from '@ngrx/store';
import {AppState} from '../index';
import {WorkspaceUserMetaActions} from './index';
import {WorkspaceService} from '../../core/services/workspace.service';
import {catchError, concatMap, map, mergeMap, switchMap, withLatestFrom} from 'rxjs/operators';
import {EMPTY, of} from 'rxjs';
import {ErrorsActions} from '../errors';
import {
  selectNodeMetasWithCurrentSlug,
  selectUserNodeMetasById,
} from './workspace-user-meta.selectors';
import {workspaceUserMetaUtils} from './workspace-user-meta.utils';
import {selectWorkspacesCurrentSlug} from '../workspaces/workspaces.selectors';
import {AppLayoutSelectors} from '../app-layout';
import {WorkspacesSelectors} from '../workspaces';
import {AssignmentDisplayMode} from '../../core/constants/assignment-display-mode';
import {NodesActions} from '../nodes';
import {AssignmentLayoutMode} from '../../core/constants/assignment-layout-mode';

@Injectable()
export class WorkspaceUserMetaEffects {
  constructor(
    private actions$: Actions,
    private store: Store<AppState>,
    private workspaceService: WorkspaceService,
  ) {
  }

  toggleFolderTreeExpansionFromSidebarRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.toggleFolderTreeExpansionFromSidebarRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectUserNodeMetasById)))),
      ),
      map(([action, nodeMetasById]) => {
        const newNodeMetasById = workspaceUserMetaUtils.toggleFolderTreeMetaExpansion(
          action.nodeId,
          nodeMetasById,
        );
        return WorkspaceUserMetaActions.updateNodeMetasByIdRequest({
          nodeMetasById: newNodeMetasById,
        });
      }),
    ),
  );

  expandFolderAfterAddingChildFolderRequest = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.expandFolderAfterAddingChildFolderRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectUserNodeMetasById)))),
      ),
      map(([action, nodeMetasById]) => {
        const newNodeMetasById = workspaceUserMetaUtils.expandFolderMetaExpansion(
          action.nodeId,
          nodeMetasById,
        );
        return WorkspaceUserMetaActions.updateNodeMetasByIdRequest({
          nodeMetasById: newNodeMetasById,
        });
      }),
    ),
  );

  replaceNodeMetasByIdInDatabaseRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        WorkspaceUserMetaActions.updateNodeMetasByIdRequest,
        WorkspaceUserMetaActions.toggleSingleFolderHiddenRequest,
      ),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectNodeMetasWithCurrentSlug)))),
      ),
      mergeMap(([action, {nodeMetasById, slug}]) =>
        this.workspaceService.updateWorkspaceUserMeta(slug, {nodeMetasById}).pipe(
          switchMap(() => EMPTY),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({error}));
          }),
        ),
      ),
    ),
  );

  changeAssignmentDateRangeInDatabaseRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAssignmentDateRangeRequest),
      concatMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(WorkspacesSelectors.selectWorkspacesCurrentSlug)),
            this.store.pipe(select(AppLayoutSelectors.selectWorkspaceContent)),
          ),
        ),
      ),
      mergeMap(([action, slug, workspaceContent]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug || action.slug, {assignmentDateRange: action.dateRange})
          .pipe(
            switchMap(data => {
              switch (workspaceContent.subMode) {
                case AssignmentDisplayMode.ProjectPricingSummary:
                case AssignmentDisplayMode.AssetPricingSummary:
                  return EMPTY;

                case AssignmentDisplayMode.Calender:
                case AssignmentDisplayMode.Table:
                case AssignmentDisplayMode.Timesheets:
                case AssignmentDisplayMode.ProjectPricing:
                default:
                  return of(
                    NodesActions.loadAssignmentsByDateRangeRequest({includeWidgets: true}),
                  );
              }
            }),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeLastScheduleLayoutRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeLastScheduleLayoutRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {lastScheduleLayout: action.scheduleLayout})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeLastPlanBoardLayoutRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeLastPlanBoardLayoutRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {lastPlanBoardLayout: action.planBoardLayout})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeGanttChartGroupByRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeGanttChartGroupByRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {ganttChartGroupBy: action.groupBy})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeAssignmentViewInDatabaseRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAssignmentViewRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {assignmentViewType: action.viewType})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeGanttChartViewRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeGanttChartViewRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {ganttChartViewType: action.viewType})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeGanttChartViewSettingsRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeGanttChartViewSettingsRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {ganttChartViewSettings: action.viewSettings})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeAssignmentViewTagsNodeTypesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAssignmentViewTagsNodeTypesRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {assignmentViewTagsNodeTypes: action.nodeTypes})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeAssignmentViewWidgetsNodeTypesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAssignmentViewWidgetsNodeTypesRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {assignmentViewWidgetsNodeTypes: action.nodeTypes})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeFiguresViewTypesRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeFiguresViewTypesRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {overlaysViewSettings: action.viewTypes})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeAwaitingAssignmentViewModeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAwaitingAssignmentViewModeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {awaitingAssignmentsViewMode: action.mode})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeAssignmentHeaderDetailsViewModeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAssignmentHeaderDetailsViewModeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {assignmentHeaderDetailsViewMode: action.mode})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeAssignmentAssetProfilePhotoViewModeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAssignmentAssetProfilePhotoViewModeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {assignmentAssetProfilePhotoViewMode: action.mode})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeAssignmentAssetsAsListViewModeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAssignmentAssetsAsListViewModeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {assignmentAssetsAsListViewMode: action.mode})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeAssignmentOverlayViewModeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAssignmentOverlayViewModeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {assignmentOverlayViewMode: action.mode})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  setContentPanelTagsViewModeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setContentPanelTagsViewModeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {contentPanelTagsViewMode: action.mode})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  setContentPanelWidgetsViewModeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setContentPanelWidgetsViewModeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {contentPanelWidgetsViewMode: action.mode})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  setContentPanelLayoutTemplateViewModeRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setContentPanelLayoutTemplateViewModeRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {contentPanelLayoutTemplateViewMode: action.mode})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeAssignmentGroupingInDatabaseRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAssignmentGroupingRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {assignmentGrouping: action.grouping})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  changeAssignmentSortingRequest$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.changeAssignmentSortingRequest),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {assignmentSorting: action.sorting})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  setSidebarLeft$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setSidebarLeft),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService.updateWorkspaceUserMeta(slug, {sidebarLeft: action.visible}).pipe(
          switchMap(() => EMPTY),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({error}));
          }),
        ),
      ),
    ),
  );

  setSidebarRight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setSidebarRight),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService.updateWorkspaceUserMeta(slug, {sidebarRight: action.visible}).pipe(
          switchMap(() => EMPTY),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({error}));
          }),
        ),
      ),
    ),
  );

  setTableProjectIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setTableProjectIds),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService.updateWorkspaceUserMeta(slug, {tableProjectIds: action.ids}).pipe(
          switchMap(() => EMPTY),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({error}));
          }),
        ),
      ),
    ),
  );

  setTableAssetIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setTableAssetIds),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService.updateWorkspaceUserMeta(slug, { tableAssetIds: action.ids }).pipe(
          switchMap(() => EMPTY),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  setTableTagIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setTableTagIds),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService.updateWorkspaceUserMeta(slug, { tableTagIds: action.ids }).pipe(
          switchMap(() => EMPTY),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({ error }));
          }),
        ),
      ),
    ),
  );

  setTableReportPeriods$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setTableReportPeriods),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, { tableReportPeriods: action.periods })
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );

  // setTableReportPeriod$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(WorkspaceUserMetaActions.setTableReportPeriod),
  //     concatMap(action =>
  //       of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
  //     ),
  //     mergeMap(([action, slug]) =>
  //       this.workspaceService
  //         .updateWorkspaceUserMeta(slug, {tableReportPeriod: action.period})
  //         .pipe(
  //           switchMap(() => EMPTY),
  //           catchError(error => {
  //             return of(ErrorsActions.goToErrorPage({error}));
  //           }),
  //         ),
  //     ),
  //   ),
  // );

  setTableGroupIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setTableGroupIds),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService.updateWorkspaceUserMeta(slug, {tableGroupIds: action.ids}).pipe(
          switchMap(() => EMPTY),
          catchError(error => {
            return of(ErrorsActions.goToErrorPage({error}));
          }),
        ),
      ),
    ),
  );

  setTableNodeTemplateIds$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.setTableNodeTemplateIds),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {tableNodeTemplateIds: action.ids})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  updateWorkspaceUserProfileIcon$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.updateWorkspaceUserProfileIcon),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {profileIcon: action.profileIcon})
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({error}));
            }),
          ),
      ),
    ),
  );

  clearAllFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspaceUserMetaActions.clearAllFilters),
      concatMap(action =>
        of(action).pipe(withLatestFrom(this.store.pipe(select(selectWorkspacesCurrentSlug)))),
      ),
      mergeMap(([action, slug]) =>
        this.workspaceService
          .updateWorkspaceUserMeta(slug, {
            tableNodeTemplateIds: [],
            tableGroupIds: [],
            tableTagIds: [],
            // tableReportPeriod: '',
            tableAssetIds: [],
            tableProjectIds: [],
            tableReportPeriods: [],
          })
          .pipe(
            switchMap(() => EMPTY),
            catchError(error => {
              return of(ErrorsActions.goToErrorPage({ error }));
            }),
          ),
      ),
    ),
  );
}
