import { RevenueRecordStatusType } from '../constants/revenue-record-status-type';

const calculateDefaultRevenueRecordStatus = (item: any) => {
  // Check database value first, as it trumps all
  if (item.revenueRecordStatus && item.revenueRecordStatus !== '') {
    return parseInt(item.revenueRecordStatus);
  }
  // if both values are empty, then Pending
  if ((!item.reportPeriod || item.reportPeriod === '') && (!item.title || item.title === '')) {
    return RevenueRecordStatusType.Pending;
  }
  // if either of them is empty (but not both) then Review
  if (!item.reportPeriod || item.reportPeriod === '' || !item.value || item.value === 0) {
    return RevenueRecordStatusType.Partial;
  }
  // if both values are not empty, then Complete
  if (item.reportPeriod && item.reportPeriod !== '' && item.title && item.title !== '') {
    return RevenueRecordStatusType.Complete;
  }

  // setting Pending as default in case we missed something
  return RevenueRecordStatusType.Pending;
}

export {
  calculateDefaultRevenueRecordStatus,
};
