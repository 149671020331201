<div
  class="z-table__input z-table__input--select"
  [ngClass]="editable ? 'editable' : 'date-picker_label_with_toggle--readonly'"
  (click)="changePeriodsValue()"
>
  <month-date-picker
    #monthYearPickerTableEditor
    [monthYearCtrl]="monthYearCtrl"
    (onDateChange)="onDateChange($event)"
  ></month-date-picker>
</div>
