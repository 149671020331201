import { Component, OnInit } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { zTableProp } from '../../table.common';
import { dateFormatFromYYYYMM } from '../../../../core/utils/date.util';

@Component({
  selector: 'app-z-table-month-year-string-list',
  templateUrl: './table-month-year-string-list.component.html',
  styleUrls: ['./table-month-year-string-list.component.scss'],
})
export class TableMonthYearStringListComponent extends AbstractTableEditableCellComponent
  implements OnInit {
  public displayValue: any;

  constructor(public table: TableComponent) {
    super(table);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    if (this.cell.value === '') {
      // handling the case where the date list is empty
      this.displayValue = '-';
    } else {
      const monthYearArray = String(this.cell.value).split(',');
      monthYearArray.sort((a, b) => b.trim().localeCompare(a.trim()));

      //if more than 2 values, just show first and last
      if (monthYearArray.length > 2) {
        this.displayValue =
          dateFormatFromYYYYMM(monthYearArray[0]) +
          ' ... ' +
          dateFormatFromYYYYMM(monthYearArray[monthYearArray.length - 1]);
      } else {
        this.displayValue = monthYearArray.map(item => dateFormatFromYYYYMM(item)).join(', ');
      }
    }
  }

  public onStartEdit() {
    super.onStartEdit();
  }

  protected readonly zTableProp = zTableProp;
}
