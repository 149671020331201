import { Component, Input, OnInit } from '@angular/core';
import { TableComponent } from '../../table.component';
import { AbstractTableEditableCellComponent } from '../abstract-table-editable-cell.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NodeRateValuesComponent } from '../../../modals/node-rate-values/node-rate-values.component';
import { NodeModel } from '../../../../core/models/node.model';

@Component({
  selector: 'app-z-table-editable-node-rate-values',
  templateUrl: './table-editable-node-rate-values.component.html',
  styleUrls: ['./table-editable-node-rate-values.component.scss'],
})
export class TableEditableNodeRateValuesComponent extends AbstractTableEditableCellComponent
  implements OnInit {
  @Input()
  assignment: NodeModel;
  constructor(public table: TableComponent, private modalService: NgbModal) {
    super(table);
  }
  public ngOnInit(): void {
    super.ngOnInit();
    // console.log('assignment', this.options?.assignment);
    this.assignment = this.options?.assignment as NodeModel;
  }
  onClick() {
    console.log('Clicked');
    const modelRef = this.modalService.open(NodeRateValuesComponent, {
      size: 'xxl',
      backdrop: 'static',
    });

    modelRef.componentInstance.project = this.assignment.reference;
    modelRef.componentInstance.assignment = this.assignment;
    modelRef.componentInstance.type = 'Revenue';
  }
}
