export enum RateType {
  Income = 1,
  Expense = 2,
  Contract = 3,
  Variation = 4,
  Rates = 5,
  Labour = 6,
  Equipment = 7,
  Materials = 8,
  Contractors = 9,
  Professionals = 10,
  Administration = 11,
}

export const RateTypeOptions = [
  RateType.Rates,
  RateType.Contract,
  RateType.Variation,
];

export const RateTypeCostOptions = [
  RateType.Labour,
  RateType.Equipment,
  RateType.Materials,
  RateType.Contractors,
  RateType.Professionals,
  RateType.Administration,
];
