import { Component, Input, OnInit } from '@angular/core';
import { NodeType } from '../../../../core/constants/node-type';
import { WorkspaceGroupType } from '../../../../core/constants/workspace-group-type';
import { CalculationWidgetModel } from '../../../../core/models/calculation-widget.model';
import { NodeTemplateModel } from '../../../../core/models/node-template.model';
import { WorkspaceGroupModel } from '../../../../core/models/workspace-group.model';

@Component({
  selector: 'app-to-z-apps',
  templateUrl: './to-z-apps.component.html',
  styleUrls: ['./to-z-apps.component.scss'],
})
export class ToZAppsComponent implements OnInit {
  @Input() public selectedTemplate: NodeTemplateModel;
  @Input() public selectedTemplates: NodeTemplateModel[];
  @Input() public selectedAssetTemplates: NodeTemplateModel[];
  @Input() public selectedFormTemplates: NodeTemplateModel[];
  @Input() public stampTagGroups: WorkspaceGroupModel[];
  @Input() public calculationWidgets: CalculationWidgetModel[];
  @Input() public calIds: number[];
  @Input() public disabled: boolean = false;
  @Input() public showOptions: string[] = [
    'Forms',
    'Financials',
    'Status Tags',
    'Calculations',
    'Gantt Chart',
  ];
  public nodeTypes = NodeType;
  public readonly WorkspaceGroupType = WorkspaceGroupType;
  constructor() {}

  ngOnInit(): void {}
}
