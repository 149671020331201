export enum RevenueRecordStatusType {
  Pending = 1,
  Partial = 2,
  Complete = 3,
}

export const RevenueRecordStatusTypeOptions = [
  RevenueRecordStatusType.Pending,
  RevenueRecordStatusType.Partial,
  RevenueRecordStatusType.Complete,
];
