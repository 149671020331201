import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { PipesModule } from '../../pipes/_pipes.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { ComponentsModule } from '../../components/_components.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TableRowNumberComponent } from './table-row-number/table-row-number.component';
import { TableEditableTextComponent } from './table-editable-text/table-editable-text.component';
import { TableEditableTextareaComponent } from './table-editable-textarea/table-editable-textarea.component';
import { TableEditableUrlComponent } from './table-editable-url/table-editable-url.component';
import { TableEditableDateComponent } from './table-editable-date/table-editable-date.component';
import { TableEditableNumberComponent } from './table-editable-number/table-editable-number.component';
import { TableEditableSelectComponent } from './table-editable-select/table-editable-select.component';
import { TableEditableIconComponent } from './table-editable-icon/table-editable-icon.component';
import { TableEditableColorComponent } from './table-editable-color/table-editable-color.component';
import { TableEditableAssignmentTagComponent } from './table-editable-assignment-tag/table-editable-assignment-tag.component';
import { TableEditableAssignmentElementsComponent } from './table-editable-assignment-elements/table-editable-assignment-elements.component';
import { TableEditableAssignmentCalculationComponent } from './table-editable-assignment-calculation/table-editable-assignment-calculation.component';
import { TableEditableWorkspaceTagPreviewComponent } from './table-editable-workspace-tag-preview/table-editable-workspace-tag-preview.component';
import { TableEditableWorkspaceGroupComponent } from './table-editable-workspace-group/table-editable-workspace-group.component';
import { TableEditableWorkspaceRateComponent } from './table-editable-workspace-rate/table-editable-workspace-rate.component';
import { TableEditorsComponent } from './table-editors.component';
import { TableEditableAssignmentCheckboxComponent } from './table-editable-assignment-checkbox/table-editable-assignment-checkbox.component';
import { TableEditableAssignmentLockComponent } from './table-editable-assignment-lock/table-editable-assignment-lock.component';
import { TableEditableAssignmentMenuComponent } from './table-editable-assignment-menu/table-editable-assignment-menu.component';
import { TableEditableAssignmentAdditionalTableFieldsComponent } from './table-editable-assignment-additional-table-fields/table-editable-assignment-additional-table-fields.component';
import { TableEditableAssignmentTagsComponent } from './table-editable-assignment-tags/table-editable-assignment-tags.component';
import { TableEditableAssignmentTemplatesComponent } from './table-editable-assignment-templates/table-editable-assignment-templates.component';
import { TableEditableAddressComponent } from './table-editable-address/table-editable-address.component';
import { TableEditableFolderPickerComponent } from './table-editable-folder-picker/table-editable-folder-picker.component';
import { TableEditableAssignedTemplateComponent } from './table-editable-assigned-template/table-editable-assigned-template.component';
import { TableEditableAssetProfileComponent } from './table-editable-asset-profile/table-editable-asset-profile.component';
import { TableEditableTimeComponent } from './table-editable-time/table-editable-time.component';
import { TableNumberLinkComponent } from './table-number-link/table-number-link.component';
import { TableEditableMonthYearComponent } from './table-editable-month-year/table-editable-month-year.component';
import { TableEditableDropdownComponent } from './table-editable-dropdown/table-editable-dropdown.component';
import { TableEditableNodeRateValuesComponent } from './table-editable-node-rate-values/table-editable-node-rate-values.component';
import { TableMonthYearStringListComponent } from './table-month-year-string-list/table-month-year-string-list.component';

@NgModule({
  declarations: [
    TableEditorsComponent,
    TableRowNumberComponent,
    TableEditableTextComponent,
    TableEditableTextareaComponent,
    TableEditableUrlComponent,
    TableEditableDateComponent,
    TableEditableMonthYearComponent,
    TableEditableTimeComponent,
    TableEditableNumberComponent,
    TableEditableAddressComponent,
    TableEditableSelectComponent,
    TableEditableIconComponent,
    TableEditableColorComponent,
    TableEditableAssignmentTagComponent,
    TableEditableAssignmentTagsComponent,
    TableEditableAssignmentElementsComponent,
    TableEditableAssignmentCalculationComponent,
    TableEditableAssignmentCheckboxComponent,
    TableEditableAssignmentLockComponent,
    TableEditableAssignmentMenuComponent,
    TableEditableWorkspaceTagPreviewComponent,
    TableEditableWorkspaceGroupComponent,
    TableEditableWorkspaceRateComponent,
    TableEditableAssignmentAdditionalTableFieldsComponent,
    TableEditableAssignmentTemplatesComponent,
    TableEditableFolderPickerComponent,
    TableEditableAssignedTemplateComponent,
    TableEditableAssetProfileComponent,
    TableNumberLinkComponent,
    TableEditableMonthYearComponent,
    TableEditableDropdownComponent,
    TableEditableNodeRateValuesComponent,
    TableMonthYearStringListComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    DragDropModule,
    PipesModule,
    NgbPopoverModule,
    NgSelectModule,
    // Custom Imports
    ComponentsModule,
  ],
  exports: [
    TableEditorsComponent,
    TableRowNumberComponent,
    TableEditableTextComponent,
    TableEditableTextareaComponent,
    TableEditableUrlComponent,
    TableEditableDateComponent,
    TableEditableMonthYearComponent,
    TableEditableTimeComponent,
    TableEditableNumberComponent,
    TableEditableAddressComponent,
    TableEditableSelectComponent,
    TableEditableIconComponent,
    TableEditableColorComponent,
    TableEditableAssignmentTagComponent,
    TableEditableAssignmentTagsComponent,
    TableEditableAssignmentElementsComponent,
    TableEditableAssignmentCalculationComponent,
    TableEditableAssignmentCheckboxComponent,
    TableEditableAssignmentLockComponent,
    TableEditableAssignmentMenuComponent,
    TableEditableWorkspaceTagPreviewComponent,
    TableEditableWorkspaceGroupComponent,
    TableEditableWorkspaceRateComponent,
    TableEditableAssignmentTemplatesComponent,
    TableEditableFolderPickerComponent,
    TableEditableAssignedTemplateComponent,
    TableEditableAssetProfileComponent,
    TableNumberLinkComponent,
    TableMonthYearStringListComponent,
  ],
  providers: [],
})
export class TableEditorsModule {}
