import { TableCell, TableRow } from '../table.common';

export enum TableEditor {
  Spacer,
  RowNumber,
  Text,
  Textarea,
  Url,
  Number,
  Date,
  Select,
  Icon,
  Color,
  WorkspaceTagPreview,
  WorkspaceGroup,
  WorkspaceRate,
  AssignmentTag,
  AssignmentAssets,
  AssignmentCalculation,
  AssignmentCheckbox,
  AssignmentLock,
  AssignmentMenu,
  AssignmentAdditionalTableFields,
  AssignmentTags,
  AssignmentTemplates,
  checkbox,
  FolderPicker,
  AssignedTemplate,
  AssetProfile,
  Time,
  NumberLink,
  MonthYear,
  MonthYearStringList,
  NodeRateValues,
}

export type TableCellEditEvent = {
  type: string;
  field: string;
  row: TableRow;
  cell: TableCell;
  previousValue: any;
};
