<div class="despatch-resources">
  <!-- First row -->
  <div class="header">
    <span class="title">Despatch Schedule</span>
    <button class="btn btn-success" (click)="selectAll()">Select All</button>
  </div>

  <!-- Second row -->
  <div class="items">
    <div class="item" *ngFor="let item of items">
      <app-btn-check
        class="leaf-list-item__icon leaf-list-item__icon--check"
        [isChecked]="isItemSelected(item.id)"
        [isDisabled]="item?.email ? false : true"
        (click)="toggleLeaf(item)"
      ></app-btn-check>
      <span>{{ item.name }}</span>
      <span
        class="edit-icon fal fa-edit ml-3"
        role="button"
        (click)="editProperties(item?.id)"
      ></span>
    </div>
    <ng-container *ngIf="!items.length">
      <app-no-data [type]="noDataTypeIds.NoRecipients"></app-no-data>
    </ng-container>
  </div>

  <!-- Bottom of the page -->
  <div class="footer">
    <label
    >Send to:
      <app-btn-tooltip
        class="panel-subsection__title-tooltip"
        [text]="
          'You can add multiple recipients by separating them with a comma. or you can select the recipients from the list above.'
        "
      ></app-btn-tooltip>
    </label>

    <textarea
      [ngClass]="{
        'has-value': recipients
      }"
      placeholder="Add recipients here...."
      [(ngModel)]="recipients"
      class="msg-box form-control form-control-sm"
      appAutoFocus
      [cdkTextareaAutosize]="true"
    ></textarea>

    <label class="message my-2"
    >Additional message:
      <app-btn-tooltip
        class="panel-subsection__title-tooltip"
        [text]="
          'You can add an additional message to the recipients. This message will be included in the email.'
        "
      ></app-btn-tooltip>
    </label>

    <textarea
      [ngClass]="{
        'has-value': msg
      }"
      placeholder="Add additional message here...."
      [(ngModel)]="msg"
      class="msg-box form-control form-control-sm"
      [cdkTextareaAutosize]="true"
    ></textarea>

    <div class="buttons">
      <button class="btn btn-danger" (click)="onCancelValue()">Cancel</button>
      <button class="btn btn-primary" (click)="despatchMsg()" [disabled]="!recipients">
        Despatch
      </button>
    </div>
  </div>
</div>
