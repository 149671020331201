<ng-template #rowMenu let-row="row" let-index="index">
  <ul class="popover-menu">
    <li class="popover-menu__item" (click)="onTableInsert(row, index)">Insert</li>
    <li class="popover-menu__item" (click)="onTableCopy(row, index)">Copy</li>
    <li class="popover-menu__divider"></li>
    <li class="popover-menu__item" (click)="onTableDelete(row, index)">Delete</li>
  </ul>
</ng-template>

<app-z-table
  sortBy="rateType"
  groupBy="rateType"
  [allowNewRow]="!readonly"
  [rowMenu]="!readonly ? rowMenu : null"
  [headerColumns]="tableColumns"
  [bodyRows]="tableBody"
  (moveGroup)="onTableGroupMove($event)"
  (moveRow)="onTableRowMove($event)"
  (endEdit)="onTableEdit($event)"
  (newRow)="onTableNewRow($event)"
  hideSearch="true"
>
</app-z-table>
