import {DateRangeModel} from '../../core/models/date-range.model';
import {dateRangeUtil} from '../../core/utils/date-range.util';
import {defaultSortingOption} from '../../core/models/sorting-option.model';
import {ToggleViewMode} from '../../core/constants/toggle-view-mode';
import {CustomViewNodeType} from '../../core/constants/custom-view-node-type';
import {AssignmentLayoutMode} from '../../core/constants/assignment-layout-mode';
import {AssignmentViewType} from '../../core/constants/assignment-view-type';
import {GroupingType} from '../../core/constants/grouping-type';
import {NodeMetasById} from '../../core/models/node-meta';
import {GanttChartGroupByType} from '../../core/constants/gantt-chart-group-by-type';
import {OverlaysViewSetting} from '../../core/constants/overlays-view.setting';
import {GanttChartViewType} from '../../core/constants/gantt-chart-view-type';
import {
  ganttChartRelaxedViewSettings,
  GanttChartViewSettingsModel,
} from '../../core/models/gantt-chart-view-settings.model';
import {ScheduleLayoutType} from '../../core/constants/schedule-layout-type';
import {AssignmentDisplayMode} from '../../core/constants/assignment-display-mode';

export class WorkspaceUserMetaState {
  nodeMetasById: NodeMetasById;
  assignmentDateRange: DateRangeModel;
  // this will not be stored in database since it will set from router url param when page is loaded
  lastAssignmentLayout: AssignmentLayoutMode;
  lastScheduleLayout: ScheduleLayoutType;
  lastPlanBoardLayout: ScheduleLayoutType;
  ganttChartGroupBy: GanttChartGroupByType;
  assignmentViewType: AssignmentViewType;
  assignmentViewTagsNodeTypes: CustomViewNodeType[];
  assignmentViewWidgetsNodeTypes: CustomViewNodeType[];
  overlaysViewSettings: OverlaysViewSetting[];
  ganttChartViewType: GanttChartViewType;
  ganttChartViewSettings: GanttChartViewSettingsModel;
  assignmentGrouping: GroupingType;
  assignmentSorting: string;

  awaitingAssignmentsViewMode: ToggleViewMode;
  assignmentHeaderDetailsViewMode: ToggleViewMode;
  assignmentAssetProfilePhotoViewMode: ToggleViewMode;
  assignmentAssetsAsListViewMode: ToggleViewMode;
  assignmentOverlayViewMode: AssignmentDisplayMode;
  contentPanelTagsViewMode: ToggleViewMode;
  contentPanelWidgetsViewMode: ToggleViewMode;
  contentPanelLayoutTemplateViewMode: ToggleViewMode;
  defaultSelectedAssignmentChecklistId: number;
  selectedChecklistIdByAssignmentId: { [id: number]: number }; // For assignment Checklists overlay
  profileIcon: { id?: string; src?: string };

  sidebarLeft: boolean;
  sidebarRight: boolean;
  tableProjectIds: number[];
  tableAssetIds: number[];
  tableTagIds: number[];
  tableGroupIds: number[];
  tableReportPeriod?: string;
  tableReportPeriods: string[];
  tableRecordStatuses: string[];
  tableNodeTemplateIds: number[];
}

export const initialWorkspaceUserMetaState: WorkspaceUserMetaState = {
  nodeMetasById: {},
  assignmentDateRange: dateRangeUtil.default(),
  lastAssignmentLayout: AssignmentLayoutMode.schedule,
  lastScheduleLayout: ScheduleLayoutType.horizontal,
  lastPlanBoardLayout: ScheduleLayoutType.vertical,
  ganttChartGroupBy: GanttChartGroupByType.projects,
  assignmentGrouping: GroupingType.daily,
  assignmentViewType: AssignmentViewType.relaxed,
  assignmentViewTagsNodeTypes: [],
  assignmentViewWidgetsNodeTypes: [CustomViewNodeType.project],
  overlaysViewSettings: [],
  ganttChartViewType: GanttChartViewType.relaxed,
  ganttChartViewSettings: ganttChartRelaxedViewSettings,
  assignmentSorting: defaultSortingOption.id,
  awaitingAssignmentsViewMode: 'show',
  assignmentHeaderDetailsViewMode: 'hide',
  assignmentAssetProfilePhotoViewMode: 'show',
  assignmentAssetsAsListViewMode: 'hide',
  assignmentOverlayViewMode: AssignmentDisplayMode.Calender,
  contentPanelTagsViewMode: 'hide',
  contentPanelWidgetsViewMode: 'hide',
  contentPanelLayoutTemplateViewMode: 'hide',
  defaultSelectedAssignmentChecklistId: undefined,
  selectedChecklistIdByAssignmentId: {},
  profileIcon: {},

  sidebarLeft: true,
  sidebarRight: true,
  tableProjectIds: [],
  tableAssetIds: [],
  tableTagIds: [],
  tableGroupIds: [],
  tableReportPeriod: '',
  tableReportPeriods: [],
  tableRecordStatuses: [],
  tableNodeTemplateIds: [],
};
