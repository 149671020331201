<div class="z-table-searchbar hide-on-sm hide-on-md">
  <div class="z-table-searchbar__field">
    <span class="fa fa-search form-control-feedback z-table-searchbar__input-icon"></span>
    <input
      #input
      type="search"
      class="form-control z-table-searchbar__input"
      [ngClass]="{
        'z-table-searchbar__input-not-active': !keywords
      }"
      placeholder="Search Table..."
      [(ngModel)]="keywords"
      (keyup)="onSearch($event)"
      (search)="onSearch($event)"
    />
  </div>
</div>
