import { Component, Input, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SharedLinkModel } from '../../../core/models/shared-link.model';
import { PropertiesPanelActions } from '../../../store/properties-panel';
import { selectSharedViewAssignmentViewModelArraysByDateKey } from '../../../store/shared-view/shared-view-assignment-view-models.selectors';
import { WidgetType } from '../../../core/constants/widget-type';
import { environment } from '../../../../environments/environment';
import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';
import { AlertService } from '../alert/alert.service';
import { NoDataTypeIds } from '../../../core/constants/no-data-settings';

@Component({
  selector: 'app-despatch-resources',
  templateUrl: './despatch-resources.component.html',
  styleUrls: ['./despatch-resources.component.scss'],
})
export class DespatchResourcesComponent implements OnInit {
  @Input() selectedLink: SharedLinkModel;
  items: { id: number; name: string; email?: string }[] = [];
  public noDataTypeIds = NoDataTypeIds;
  recipients: string = '';
  msg: string = '';
  selectedLeafs: { id: number; name: string; email?: string }[] = [];
  constructor(private store: Store, private alertService: AlertService) {}

  ngOnInit(): void {
    this.store.pipe(select(selectSharedViewAssignmentViewModelArraysByDateKey)).subscribe(items => {
      this.processItems(items);
    });
  }

  processItems(items: any): void {
    this.items = [];
    this.recipients = '';
    this.selectedLeafs = [];
    Object.keys(items).forEach(key => {
      const listByKey = items[key];
      listByKey.forEach(itemByKey => {
        itemByKey?.assetGroups
          ?.filter(assetGroup => assetGroup?.allowDispatch === true)
          .forEach(assetGroup => {
            assetGroup?.nodes?.forEach(node => {
              const asset = node?.reference;
              const item: any = {
                id: asset.id,
                name: asset.title,
                email: this.getAssetEmail(asset.widgets),
              };
              if (!this.items.some(existingItem => existingItem.id === item.id)) {
                this.items.push(item);
              }
            });
          });
      });
    });
  }

  isItemSelected(id: number): boolean {
    return this.selectedLeafs?.find(item => item?.id === id) ? true : false;
  }

  getAssetEmail(widgets: any[]): string {
    const emailWidget = widgets?.find(
      widgetItem => widgetItem.widget?.widgetType === WidgetType.email,
    );
    return emailWidget ? emailWidget?.value : null;
  }

  selectAll(): void {
    this.selectedLeafs = this.items?.filter(item => item?.email);
    this.updateTextBox();
  }

  onCancelValue() {
    this.recipients = '';
    this.selectedLeafs = [];
    this.msg = '';
  }

  toggleLeaf(item: { id: number; name: string; email?: string }): void {
    const index = this.selectedLeafs?.findIndex(leaf => leaf?.id === item?.id);
    if (index === -1) {
      this.selectedLeafs.push(item);
    } else {
      this.selectedLeafs.splice(index, 1);
    }
    // add emails to recipients as a comma separated string
    this.updateTextBox();
  }

  updateTextBox(): void {
    this.recipients = this.selectedLeafs?.length
      ? this.selectedLeafs.map(leaf => leaf?.email).join(', ')
      : '';
  }

  editProperties(nodeId: number): void {
    // open properties panel
    this.store.dispatch(
      PropertiesPanelActions.openPropertiesPanel({
        nodeId: nodeId,
      }),
    );
  }

  despatchMsg(): void {
    // get emails from msg variable by removing the comma and splitting the string
    const emails = this.recipients.split(',').map(email => email.trim());
    emails.forEach(email => {
      // Example usage
      const selectedLeaf = this.selectedLeafs.find(item => item.email === email);

      const subject = this.selectedLink?.metaData?.title;
      const toName = selectedLeaf ? selectedLeaf?.name : email;
      const toEmail = email;
      const link = `${environment?.clientUrl}/shared-view/${this.selectedLink?.hash}`;
      // const message = `Hi ${item?.name},
      // Here's the link to your upcoming work schedule for the ${subject}:
      // ${link}`;
      this.sendEmail(toName, toEmail, subject, link);
    });
    // send email to selectedLeafs
    this.recipients = '';
    this.msg = '';
    this.selectedLeafs = [];
  }

  sendEmail(toName: string, toEmail: string, subject: string, link: string): void {
    const templateParams = {
      to_name: toName,
      from_email: 'no-reply@zedulrdespatch.com',
      to_email: toEmail,
      subject: subject,
      title: subject,
      link: link,
      message: this.msg,
    };

    const serviceId = 'service_5arjyrg';
    const templateId = 'template_das5zat';
    const publicId = 'M4he18ki9jDIlpkpB';

    emailjs
      .send(serviceId, templateId, templateParams, {
        publicKey: publicId,
      })
      .then(
        (response: EmailJSResponseStatus) => {
          this.alertService.success(`Email sent successfully to ${toName}!`);
        },
        error => {
          console.error('Error sending email', error);
          this.alertService.error(
            `Error sending email to ${toName}!, Please ensure the email is correct.`,
          );
        },
      );
  }
}
