import {NgModule} from '@angular/core';
import {TagsPickerComponent} from './tags-picker/tags-picker.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ComponentsModule} from '../components/_components.module';
import {DirectivesModule} from '../directives/_directives.module';
import {PipesModule} from '../pipes/_pipes.module';
import {NgbModule, NgbNavModule} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmPopupComponent} from './confirm-popup/confirm-popup.component';
import {TagEditComponent} from './tag-edit/tag-edit.component';
import {ColorPickerComponent} from './color-picker/color-picker.component';
import {IconPickerComponent} from './icon-picker/icon-picker.component';
import {WorkspaceEditComponent} from './workspace-edit/workspace-edit.component';
import {ThumbnailPickerComponent} from './thumbnail-picker/thumbnail-picker.component';
import {ConfirmPopupArchiveNodeComponent} from './confirm-popup-archive-node/confirm-popup-archive-node.component';
import {ConfirmPopupMoveFolderComponent} from './confirm-popup-move-folder/confirm-popup-move-folder.component';
import {
  ConfirmPopupSessionTimeoutComponent
} from './confirm-popup-session-timeout/confirm-popup-session-timeout.component';
import {GroupEditComponent} from './group-edit/group-edit.component';
import {WorkspaceTemplateEditComponent} from './workspace-edit/workspace-template-edit.component';
import {WidgetsPickerComponent} from './widgets-picker/widgets-picker.component';
import {WidgetEditComponent} from './widget-edit/widget-edit.component';
import {NodeTemplatePickerComponent} from './node-template-picker/node-template-picker.component';
import {NodePickerComponent} from './node-picker/node-picker.component';
import {NodeRatesComponent} from './node-rates/node-rates.component';
import {NodeRateValuesComponent} from './node-rate-values/node-rate-values.component';
import {GroupPickerComponent} from './group-picker/group-picker.component';
import {FolderPickerComponent} from './folder-picker/folder-picker.component';
import {DatePickerComponent} from './date-picker/date-picker.component';
import {NodeAssetPickerComponent} from './node-asset-picker/node-asset-picker.component';
import {ZedulrTableModule} from '../zedulr-tables/_zedulr-tables.module';
import {TableModule} from '../table/table.module';
import {TextEditorComponent} from './text-editor/text-editor.component';
import {LayoutAndTagPickerComponent} from './layout-and-tag-picker/layout-and-tag-picker.component';
import {
  NodeElementAndWidgetPickerComponent
} from './node-element-and-widget-picker/node-element-and-widget-picker.component';
import {LayoutPickerComponent} from './layout-picker/layout-picker.component';
import {DispatchScheduleComponent} from './dispatch-schedule/dispatch-schedule.component';
import {LayoutTemplateAddComponent} from './layout-template-add/layout-template-add.component';
import {AssignedTemplatePickerComponent} from './assigned-template-picker/assigned-template-picker.component';
import {FormsEditPanelComponent} from './forms-edit-panel/forms-edit-panel.component';
import {TagsPickerMultipleComponent} from './tags-picker-multiple/tags-picker-multiple.component';
import {NgxPrintModule} from 'ngx-print';
import {WorkspaceLinksModalComponent} from './workspace-links-modal/workspace-links-modal.component';
import {ShareLinksComponent} from './share-links/share-links.component';
import {ReportPeriodPickerComponent} from "./report-period-picker/report-period-picker.component";
import { RecordStatusPickerComponent } from './record-status-picker/record-status-picker.component';

@NgModule({
  imports: [
    ReactiveFormsModule,
    ComponentsModule,
    NgbModule,
    DirectivesModule,
    PipesModule,
    ZedulrTableModule,
    TableModule,
    NgbNavModule,
    NgxPrintModule,
  ],
  declarations: [
    NodeRatesComponent,
    NodeRateValuesComponent,
    ConfirmPopupComponent,
    TagsPickerComponent,
    TagsPickerMultipleComponent,
    ReportPeriodPickerComponent,
    RecordStatusPickerComponent,
    WidgetsPickerComponent,
    NodeTemplatePickerComponent,
    LayoutAndTagPickerComponent,
    NodePickerComponent,
    NodeAssetPickerComponent,
    FolderPickerComponent,
    GroupPickerComponent,
    TagEditComponent,
    WidgetEditComponent,
    GroupEditComponent,
    ThumbnailPickerComponent,
    ColorPickerComponent,
    IconPickerComponent,
    DatePickerComponent,
    WorkspaceEditComponent,
    WorkspaceTemplateEditComponent,
    DispatchScheduleComponent,
    ConfirmPopupArchiveNodeComponent,
    ConfirmPopupMoveFolderComponent,
    ConfirmPopupSessionTimeoutComponent,
    TextEditorComponent,
    NodeElementAndWidgetPickerComponent,
    LayoutPickerComponent,
    LayoutTemplateAddComponent,
    AssignedTemplatePickerComponent,
    FormsEditPanelComponent,
    WorkspaceLinksModalComponent,
    ShareLinksComponent,
  ],
  exports: [
    NodeRatesComponent,
    ConfirmPopupComponent,
    TagsPickerComponent,
    TagsPickerMultipleComponent,
    ReportPeriodPickerComponent,
    RecordStatusPickerComponent,
    WidgetsPickerComponent,
    NodeTemplatePickerComponent,
    LayoutAndTagPickerComponent,
    NodePickerComponent,
    NodeAssetPickerComponent,
    FolderPickerComponent,
    GroupPickerComponent,
    TagEditComponent,
    WidgetEditComponent,
    GroupEditComponent,
    ThumbnailPickerComponent,
    ColorPickerComponent,
    IconPickerComponent,
    DatePickerComponent,
    WorkspaceEditComponent,
    WorkspaceTemplateEditComponent,
    DispatchScheduleComponent,
    ConfirmPopupArchiveNodeComponent,
    ConfirmPopupMoveFolderComponent,
    ConfirmPopupSessionTimeoutComponent,
    AssignedTemplatePickerComponent,
    TextEditorComponent,
    WorkspaceLinksModalComponent,
    ShareLinksComponent,
  ],
})
export class ModalsModule {
}
