<div
  class="selectable-brick "
  [ngClass]="[
    'selectable-brick--' + (color ? (color | colorString) : 'transparent'),
    isChecked ? 'selectable-brick--checked' : '',
    sizeType ? 'selectable-brick--' + sizeType : '',
    readonly ? 'selectable-brick--readonly' : '',
    flexColumn ? 'selectable-brick--flex-column' : ''
  ]"
>
  <span class="selectable-brick__left-col">
    <span class="selectable-brick__check-icon fal fa-check-square"></span>
    <span
      *ngIf="!hideIcon"
      class="selectable-brick__item-icon fal"
      [ngClass]="[
        'fa-' + (icon | iconString),
        icon ? '' : 'invisible',
        flexColumn ? 'selectable-brick__item-icon--flex-column' : ''
      ]"
    ></span>
    {{ formattedLabel }}
    <ng-content></ng-content>
  </span>
  <span class="selectable-brick__right-col">
    <ng-container *ngTemplateOutlet="rightColTemplate"></ng-container>
  </span>
</div>
<ng-content></ng-content>
