import { createAction, props } from '@ngrx/store';
import {
  NodeRateValueEditableProps,
  NodeRateValueModel,
} from '../../core/models/node-rate-value.model';

export const loadNodeRateValuesFromNodeTree = createAction(
  '[NodeRateValues] load node rate values from node tree success',
  props<{ nodeRateValues: NodeRateValueModel[] }>(),
);

export const addNodeRateValueRequest = createAction(
  '[NodeRateValues] add node rate value request',
  props<{
    nodeId: number;
    nodeRateId?: number;
    //groupId?: number;
    reportPeriod?: string;
    quantity?: number;
    revenueRecordStatus?: string;
  }>(),
);

export const addNodeRateValueSuccess = createAction(
  '[NodeRateValues] add node rate value success',
  props<{
    rateValue: NodeRateValueModel;
  }>(),
);

export const updateNodeRateValueRequest = createAction(
  '[NodeRateValues] update node rate value request',
  props<{ rateValueId: number; rateValueProps: Partial<NodeRateValueEditableProps> }>(),
);

export const deleteNodeRateValueRequest = createAction(
  '[NodeRateValues] delete node rate value request',
  props<{ nodeId: number; rateValueId: number }>(),
);
