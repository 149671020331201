<div
  *ngIf="isLoading && !headerLogo"
  [ngClass]="[
    'loader',
    'loader-content',
    'loader-content--' + (scope || 'empty'),
    !positionAbsolute ? 'loader-content--sticky' : ''
  ]"
  (click)="onClick($event)"
>
  <ng-lottie [options]="{ 'path': 'assets/lottie/loader.json' }"></ng-lottie>
</div>

<span 
    class="secured-header__logo-container secured-header__logo secured-header__logo--desktop"
    *ngIf="headerLogo"
    (click)="onClick($event)"
>
  <ng-lottie [options]="{ 'autoplay': isLoading, 'path': 'assets/lottie/zedulr-logo-loader.json' }"></ng-lottie>
</span>
