import { Action, createReducer, on } from '@ngrx/store';
import { initialWorkspaceUserMetaState, WorkspaceUserMetaState } from './workspace-user-meta.state';
import { WorkspaceUserMetaActions } from './index';
import { dateRangeUtil } from '../../core/utils/date-range.util';

const reducer = createReducer<WorkspaceUserMetaState>(
  initialWorkspaceUserMetaState,
  on(
    WorkspaceUserMetaActions.loadCurrentWorkspaceUserMetaSuccess,
    (state, { userMetaData }): WorkspaceUserMetaState => {
      return userMetaData
        ? {
            ...state,
            nodeMetasById:
              userMetaData.nodeMetasById || initialWorkspaceUserMetaState.nodeMetasById,
            assignmentDateRange: dateRangeUtil.isValidDateRange(userMetaData.assignmentDateRange)
              ? userMetaData.assignmentDateRange
              : initialWorkspaceUserMetaState.assignmentDateRange,
            lastScheduleLayout:
              userMetaData.lastScheduleLayout || initialWorkspaceUserMetaState.lastScheduleLayout,
            lastPlanBoardLayout:
              userMetaData.lastPlanBoardLayout || initialWorkspaceUserMetaState.lastPlanBoardLayout,
            ganttChartGroupBy:
              userMetaData.ganttChartGroupBy || initialWorkspaceUserMetaState.ganttChartGroupBy,
            assignmentViewType:
              userMetaData.assignmentViewType || initialWorkspaceUserMetaState.assignmentViewType,
            assignmentViewTagsNodeTypes:
              userMetaData.assignmentViewTagsNodeTypes ||
              initialWorkspaceUserMetaState.assignmentViewTagsNodeTypes,
            assignmentViewWidgetsNodeTypes:
              userMetaData.assignmentViewWidgetsNodeTypes ||
              initialWorkspaceUserMetaState.assignmentViewWidgetsNodeTypes,
            ganttChartViewType:
              userMetaData.ganttChartViewType || initialWorkspaceUserMetaState.ganttChartViewType,
            ganttChartViewSettings:
              userMetaData.ganttChartViewSettings ||
              initialWorkspaceUserMetaState.ganttChartViewSettings,
            overlaysViewSettings:
              userMetaData.overlaysViewSettings ||
              initialWorkspaceUserMetaState.overlaysViewSettings,
            assignmentGrouping:
              userMetaData.assignmentGrouping || initialWorkspaceUserMetaState.assignmentGrouping,
            assignmentSorting:
              userMetaData.assignmentSorting || initialWorkspaceUserMetaState.assignmentSorting,

            awaitingAssignmentsViewMode:
              userMetaData.awaitingAssignmentsViewMode ||
              initialWorkspaceUserMetaState.awaitingAssignmentsViewMode,
            assignmentHeaderDetailsViewMode:
              userMetaData.assignmentHeaderDetailsViewMode ||
              initialWorkspaceUserMetaState.assignmentHeaderDetailsViewMode,
            assignmentAssetProfilePhotoViewMode:
              userMetaData.assignmentAssetProfilePhotoViewMode ||
              initialWorkspaceUserMetaState.assignmentAssetProfilePhotoViewMode,
            assignmentAssetsAsListViewMode:
              userMetaData.assignmentAssetsAsListViewMode ||
              initialWorkspaceUserMetaState.assignmentAssetsAsListViewMode,
            assignmentOverlayViewMode:
              userMetaData.assignmentOverlayViewMode ||
              initialWorkspaceUserMetaState.assignmentOverlayViewMode,
            contentPanelTagsViewMode:
              userMetaData.contentPanelTagsViewMode ||
              initialWorkspaceUserMetaState.contentPanelTagsViewMode,
            contentPanelWidgetsViewMode:
              userMetaData.contentPanelWidgetsViewMode ||
              initialWorkspaceUserMetaState.contentPanelWidgetsViewMode,
            contentPanelLayoutTemplateViewMode:
              userMetaData.contentPanelLayoutTemplateViewMode ||
              initialWorkspaceUserMetaState.contentPanelLayoutTemplateViewMode,

            sidebarLeft:
              userMetaData.sidebarLeft != null
                ? userMetaData.sidebarLeft
                : initialWorkspaceUserMetaState.sidebarLeft,

            sidebarRight:
              userMetaData.sidebarRight != null
                ? userMetaData.sidebarRight
                : initialWorkspaceUserMetaState.sidebarRight,

            tableProjectIds: userMetaData.tableProjectIds || [],
            tableAssetIds: userMetaData.tableAssetIds || [],
            tableTagIds: userMetaData.tableTagIds || [],
            tableGroupIds: userMetaData.tableGroupIds || [],
            // tableReportPeriod: userMetaData.tableReportPeriod || '',
            tableReportPeriods: userMetaData.tableReportPeriods || [],
            tableRecordStatuses: userMetaData.tableRecordStatuses || [],
            tableNodeTemplateIds: userMetaData.tableNodeTemplateIds || [],
          }
        : initialWorkspaceUserMetaState;
    },
  ),

  on(WorkspaceUserMetaActions.updateNodeMetasByIdRequest, (state, { nodeMetasById }) => ({
    ...state,
    nodeMetasById,
  })),

  on(WorkspaceUserMetaActions.toggleSingleFolderHiddenRequest, (state, { nodeId }) => ({
    ...state,
    nodeMetasById: {
      ...state.nodeMetasById,
      [nodeId]: {
        ...state.nodeMetasById[nodeId],
        hidden: !(state.nodeMetasById[nodeId] && state.nodeMetasById[nodeId].hidden),
      },
    },
  })),

  on(WorkspaceUserMetaActions.changeAssignmentDateRangeRequest, (state, { dateRange }) => ({
    ...state,
    assignmentDateRange: dateRange,
  })),

  on(
    WorkspaceUserMetaActions.changeLastAssignmentLayoutRequest,
    (state, { layout }): WorkspaceUserMetaState => ({
      ...state,
      lastAssignmentLayout: layout,
    }),
  ),
  on(
    WorkspaceUserMetaActions.changeLastScheduleLayoutRequest,
    (state, { scheduleLayout }): WorkspaceUserMetaState => ({
      ...state,
      lastScheduleLayout: scheduleLayout,
    }),
  ),
  on(
    WorkspaceUserMetaActions.changeLastPlanBoardLayoutRequest,
    (state, { planBoardLayout }): WorkspaceUserMetaState => ({
      ...state,
      lastPlanBoardLayout: planBoardLayout,
    }),
  ),
  on(
    WorkspaceUserMetaActions.changeGanttChartGroupByRequest,
    (state, { groupBy }): WorkspaceUserMetaState => ({
      ...state,
      ganttChartGroupBy: groupBy,
    }),
  ),
  on(WorkspaceUserMetaActions.changeAssignmentViewRequest, (state, { viewType }) => ({
    ...state,
    assignmentViewType: viewType,
  })),
  on(
    WorkspaceUserMetaActions.changeAssignmentViewTagsNodeTypesRequest,
    (state, { nodeTypes }): WorkspaceUserMetaState => ({
      ...state,
      assignmentViewTagsNodeTypes: nodeTypes,
    }),
  ),
  on(
    WorkspaceUserMetaActions.changeAssignmentViewWidgetsNodeTypesRequest,
    (state, { nodeTypes }): WorkspaceUserMetaState => ({
      ...state,
      assignmentViewWidgetsNodeTypes: nodeTypes,
    }),
  ),
  on(WorkspaceUserMetaActions.changeGanttChartViewRequest, (state, { viewType }) => ({
    ...state,
    ganttChartViewType: viewType,
  })),
  on(
    WorkspaceUserMetaActions.changeGanttChartViewSettingsRequest,
    (state, { viewSettings }): WorkspaceUserMetaState => ({
      ...state,
      ganttChartViewSettings: viewSettings,
    }),
  ),
  on(
    WorkspaceUserMetaActions.changeFiguresViewTypesRequest,
    (state, { viewTypes }): WorkspaceUserMetaState => ({
      ...state,
      overlaysViewSettings: viewTypes,
    }),
  ),

  on(WorkspaceUserMetaActions.changeAssignmentGroupingRequest, (state, { grouping }) => ({
    ...state,
    assignmentGrouping: grouping,
  })),
  on(WorkspaceUserMetaActions.changeAssignmentSortingRequest, (state, { sorting }) => ({
    ...state,
    assignmentSorting: sorting,
  })),

  on(WorkspaceUserMetaActions.changeAwaitingAssignmentViewModeRequest, (state, { mode }) => ({
    ...state,
    awaitingAssignmentsViewMode: mode,
  })),
  on(
    WorkspaceUserMetaActions.changeAssignmentHeaderDetailsViewModeRequest,
    (state, { mode }): WorkspaceUserMetaState => ({
      ...state,
      assignmentHeaderDetailsViewMode: mode,
    }),
  ),
  on(
    WorkspaceUserMetaActions.changeAssignmentAssetProfilePhotoViewModeRequest,
    (state, { mode }): WorkspaceUserMetaState => ({
      ...state,
      assignmentAssetProfilePhotoViewMode: mode,
    }),
  ),
  on(
    WorkspaceUserMetaActions.changeAssignmentAssetsAsListViewModeRequest,
    (state, { mode }): WorkspaceUserMetaState => ({
      ...state,
      assignmentAssetsAsListViewMode: mode,
    }),
  ),
  on(
    WorkspaceUserMetaActions.changeAssignmentOverlayViewModeRequest,
    (state, { mode }): WorkspaceUserMetaState => ({
      ...state,
      assignmentOverlayViewMode: mode,
    }),
  ),
  on(
    WorkspaceUserMetaActions.setContentPanelTagsViewModeRequest,
    (state, { mode }): WorkspaceUserMetaState => ({
      ...state,
      contentPanelTagsViewMode: mode,
    }),
  ),
  on(
    WorkspaceUserMetaActions.setContentPanelWidgetsViewModeRequest,
    (state, { mode }): WorkspaceUserMetaState => ({
      ...state,
      contentPanelWidgetsViewMode: mode,
    }),
  ),
  on(
    WorkspaceUserMetaActions.setContentPanelLayoutTemplateViewModeRequest,
    (state, { mode }): WorkspaceUserMetaState => ({
      ...state,
      contentPanelLayoutTemplateViewMode: mode,
    }),
  ),
  on(
    WorkspaceUserMetaActions.changeDefaultSelectedAssignmentChecklistId,
    (state, { checklistId }): WorkspaceUserMetaState => ({
      ...state,
      defaultSelectedAssignmentChecklistId: checklistId,
    }),
  ),
  on(
    WorkspaceUserMetaActions.changeSelectedChecklistIdOfAssignment,
    WorkspaceUserMetaActions.changeSelectedChecklistIdOfAssignmentFromNodeChecklistEditPopover,
    (state, { checklistId, assignmentId }): WorkspaceUserMetaState => ({
      ...state,
      selectedChecklistIdByAssignmentId: {
        ...state.selectedChecklistIdByAssignmentId,
        [assignmentId]: checklistId,
      },
    }),
  ),

  on(
    WorkspaceUserMetaActions.setSidebarLeft,
    (state, { visible }): WorkspaceUserMetaState => ({
      ...state,
      sidebarLeft: visible,
    }),
  ),

  on(
    WorkspaceUserMetaActions.setSidebarRight,
    (state, { visible }): WorkspaceUserMetaState => ({
      ...state,
      sidebarRight: visible,
    }),
  ),

  on(
    WorkspaceUserMetaActions.setTableProjectIds,
    (state, { ids }): WorkspaceUserMetaState => ({
      ...state,
      tableProjectIds: ids,
    }),
  ),

  on(
    WorkspaceUserMetaActions.setTableAssetIds,
    (state, { ids }): WorkspaceUserMetaState => ({
      ...state,
      tableAssetIds: ids,
    }),
  ),

  on(
    WorkspaceUserMetaActions.setTableTagIds,
    (state, { ids }): WorkspaceUserMetaState => ({
      ...state,
      tableTagIds: ids,
    }),
  ),

  on(
    WorkspaceUserMetaActions.setTableReportPeriods,
    (state, { periods }): WorkspaceUserMetaState => ({
      ...state,
      tableReportPeriods: periods,
    }),
  ),

  on(
    WorkspaceUserMetaActions.setTableRecordStatuses,
    (state, { statuses }): WorkspaceUserMetaState => ({
      ...state,
      tableRecordStatuses: statuses,
    }),
  ),

  on(
    WorkspaceUserMetaActions.setTableGroupIds,
    (state, { ids }): WorkspaceUserMetaState => {
      return {
        ...state,
        tableGroupIds: ids,
      };
    },
  ),

  on(
    WorkspaceUserMetaActions.setTableNodeTemplateIds,
    (state, { ids }): WorkspaceUserMetaState => {
      return {
        ...state,
        tableNodeTemplateIds: ids,
      };
    },
  ),

  on(
    WorkspaceUserMetaActions.updateWorkspaceUserProfileIcon,
    (state, { profileIcon }): WorkspaceUserMetaState => {
      return {
        ...state,
        profileIcon: profileIcon,
      };
    },
  ),

  on(
    WorkspaceUserMetaActions.clearAllFilters,
    (state): WorkspaceUserMetaState => {
      return {
        ...state,
        tableGroupIds: [],
        tableTagIds: [],
        tableAssetIds: [],
        tableProjectIds: [],
        tableNodeTemplateIds: [],
        tableReportPeriods: [],
        tableRecordStatuses: [],
      };
    },
  ),
);

export function workspaceUserMetaReducer(state: WorkspaceUserMetaState, action: Action) {
  return reducer(state, action);
}
