<ng-container [ngSwitch]="column.editor">
  <app-z-table-editable-workspace-rate
    *ngSwitchCase="TableEditor.WorkspaceRate"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-workspace-rate>

  <app-z-table-editable-workspace-group
    *ngSwitchCase="TableEditor.WorkspaceGroup"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-workspace-group>

  <app-z-table-editable-workspace-tag-preview
    *ngSwitchCase="TableEditor.WorkspaceTagPreview"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-workspace-tag-preview>

  <app-z-table-editable-icon
    *ngSwitchCase="TableEditor.Icon"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-icon>

  <app-z-table-editable-color
    *ngSwitchCase="TableEditor.Color"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-color>

  <app-z-table-editable-select
    *ngSwitchCase="TableEditor.Select"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-select>

  <app-z-table-editable-date
    *ngSwitchCase="TableEditor.Date"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-date>

  <app-z-table-editable-month-year
    *ngSwitchCase="TableEditor.MonthYear"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-month-year>

  <app-z-table-editable-time
    *ngSwitchCase="TableEditor.Time"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-time>

  <app-z-table-editable-url
    *ngSwitchCase="TableEditor.Url"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-url>

  <app-z-table-editable-number
    *ngSwitchCase="TableEditor.Number"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-number>

  <app-z-table-number-link
    *ngSwitchCase="TableEditor.NumberLink"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-number-link>

  <app-z-table-editable-textarea
    *ngSwitchCase="TableEditor.Textarea"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-textarea>

  <app-z-table-editable-assignment-checkbox
    *ngSwitchCase="TableEditor.checkbox"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-assignment-checkbox>

  <app-z-table-row-number
    *ngSwitchCase="TableEditor.RowNumber"
    [row]="row"
    [index]="sortDescending ? group.rows.length - rowIndex : rowIndex + 1"
  >
  </app-z-table-row-number>

  <app-z-table-editable-assignment-tag
    *ngSwitchCase="TableEditor.AssignmentTag"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-assignment-tag>

  <app-z-table-editable-assignment-tags
    *ngSwitchCase="TableEditor.AssignmentTags"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-assignment-tags>

  <app-z-table-editable-assignment-templates
    *ngSwitchCase="TableEditor.AssignmentTemplates"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-assignment-templates>

  <app-z-table-editable-asset-profile
    *ngSwitchCase="TableEditor.AssetProfile"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-asset-profile>

  <app-z-table-editable-assignment-elements
    *ngSwitchCase="TableEditor.AssignmentAssets"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-assignment-elements>

  <app-z-table-editable-assignment-calculation
    *ngSwitchCase="TableEditor.AssignmentCalculation"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-assignment-calculation>

  <app-table-editable-assignment-additional-table-fields
    *ngSwitchCase="TableEditor.AssignmentAdditionalTableFields"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-table-editable-assignment-additional-table-fields>

  <app-z-table-editable-assignment-lock
    *ngSwitchCase="TableEditor.AssignmentLock"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-assignment-lock>

  <app-z-table-editable-assignment-menu
    *ngSwitchCase="TableEditor.AssignmentMenu"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-assignment-menu>

  <ng-container *ngSwitchCase="TableEditor.Spacer">
    <div class="z-table__spacer">&nbsp;</div>
  </ng-container>

  <app-z-table-editable-folder-picker
    *ngSwitchCase="TableEditor.FolderPicker"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-folder-picker>

  <app-z-table-editable-assigned-template
    *ngSwitchCase="TableEditor.AssignedTemplate"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-assigned-template>

  <app-z-table-editable-node-rate-values
    *ngSwitchCase="TableEditor.NodeRateValues"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-node-rate-values>

  <app-z-table-month-year-string-list
    *ngSwitchCase="TableEditor.MonthYearStringList"
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-month-year-string-list>

  <app-z-table-editable-text
    *ngSwitchDefault
    [field]="column.id"
    [column]="column"
    [row]="row"
    [cell]="cell"
    (startEdit)="onStartEdit($event)"
    (endEdit)="onEndEdit($event)"
    [tableEditable]="tableEditable"
  >
  </app-z-table-editable-text>

</ng-container>
