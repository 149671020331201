import { Component, Input, OnInit } from '@angular/core';
import { NodeType } from '../../../../core/constants/node-type';
import { WorkspaceGroupType } from '../../../../core/constants/workspace-group-type';
import { NodeTemplateModel } from '../../../../core/models/node-template.model';
import { WorkspaceGroupModel } from '../../../../core/models/workspace-group.model';

@Component({
  selector: 'app-to-card-layout',
  templateUrl: './to-card-layout.component.html',
  styleUrls: ['./to-card-layout.component.scss'],
})
export class ToCardLayoutComponent implements OnInit {
  @Input() public selectedTemplate: NodeTemplateModel;
  @Input() public selectedTemplates: NodeTemplateModel[];
  @Input() public selectedAssetTemplates: NodeTemplateModel[];
  @Input() public selectedFormTemplates: NodeTemplateModel[];
  @Input() public groups: WorkspaceGroupModel[];
  @Input() public primaryTagGroups: WorkspaceGroupModel[];
  @Input() public disabled: boolean = false;
  @Input() public showOptions: string[] = ['Tags', 'Resources', 'Fields'];

  public readonly WorkspaceGroupType = WorkspaceGroupType;
  public readonly nodeTypes = NodeType;

  constructor() {}

  ngOnInit(): void {}
}
