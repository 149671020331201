import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResponseMessage } from '../models/response-message.model';
import { environment } from '../../../environments/environment';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { NodeRateValueEditableProps, NodeRateValueModel } from '../models/node-rate-value.model';

@Injectable({ providedIn: 'root' })
export class NodeRateValueService {
  constructor(private http: HttpClient) {}

  public addRateValue(
    nodeId: number,
    nodeRateId: number,
    //groupId: number,
    reportPeriod: string,
    quantity: number,
    revenueRecordStatus: string,
  ): Observable<NodeRateValueModel> {
    console.log('nodeRateId', nodeRateId);
    return this.http
      .post<ResponseMessage<NodeRateValueModel>>(
        `${environment.apiBaseUrl}/workspace/node/${nodeId}/rate-value/add`,
        {
          nodeRate: nodeRateId,
          // group: groupId,
          reportPeriod,
          quantity,
          revenueRecordStatus,
        },
      )
      .pipe(map(response => response.data));
  }

  public updateRateValue(
    rateValueId: number,
    rateValueProps: Partial<NodeRateValueEditableProps>,
  ): Observable<NodeRateValueModel> {
    // API expects update props match the Database Entity fields
    let props: any = { ...rateValueProps };
    if (props.groupId) {
      props.group = props.groupId;
      delete props.groupId;
    }
    if (props.nodeRateId) {
      props.nodeRate = props.nodeRateId;
      delete props.nodeRateId;
    }
    return this.http
      .patch<ResponseMessage<NodeRateValueModel>>(
        `${environment.apiBaseUrl}/workspace/node/rate-value/${rateValueId}/update`,
        {
          ...props,
        },
      )
      .pipe(map(response => response.data));
  }

  public removeRateValue(nodeId: number, rateValueId: number): Observable<ResponseMessage<any>> {
    return this.http.delete<ResponseMessage<any>>(
      `${environment.apiBaseUrl}/workspace/node/${nodeId}/rate-value/${rateValueId}/remove`,
    );
  }
}
