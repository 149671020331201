import { Component, OnInit } from '@angular/core';
import Chart from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss'],
  standalone: true,
})
export class PieChartComponent implements OnInit {
  constructor() {
    // Chart.register(ChartDataLabels);
  }

  public chart: any;
  public pieChart: any;

  data = {
    labels: ['Contribution', 'Total Cost', 'Total Revenue'],
    datasets: [
      {
        label: '$',
        data: [109405, 12295, 121700],
        backgroundColor: ['#F0ECF9', '#FFEEF2', '#E3F7F3'],
        hoverOffset: 4,
      },
    ],
  };

  ngOnInit(): void {
    this.createChart();
  }

  createChart() {
    this.chart = new Chart('contributionPieChart', {
      type: 'pie',
      data: this.data,
    });
  }
}
