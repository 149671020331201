import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { AppState } from '../../../store';
import { NodeModel } from '../../../core/models/node.model';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmPopupComponent } from '../../modals/confirm-popup/confirm-popup.component';
import { NodeRatesSelectors } from '../../../store/node-rates';
import { RateType } from '../../../core/constants/rate-type';
import {
  zTableProp,
  TableCell,
  TableColumn,
  TableColumnAlignment,
  TableGroupMoveEvent,
  TableRow,
  TableRowMoveEvent,
} from '../../table/table.common';
import { WorkspaceGroupModel } from '../../../core/models/workspace-group.model';
import { WorkspaceGroupsSelectors } from '../../../store/workspace-groups';
import { TableCellEditEvent, TableEditor } from '../../table/table-editors/table-editors.common';
import { NodeRateValueModel } from '../../../core/models/node-rate-value.model';
import { NodeRateModel } from '../../../core/models/node-rate.model';
import { NodeRateValuesActions, NodeRateValuesSelectors } from '../../../store/node-rate-values';
import {
  RevenueRecordStatusType,
  RevenueRecordStatusTypeOptions,
} from '../../../core/constants/revenue-record-status-type';

@Component({
  selector: 'app-z-table-node-rate-values',
  templateUrl: './nodes-rate-values-table.component.html',
  styleUrls: ['./nodes-rate-values-table.component.scss'],
})
export class NodesRateValuesTableComponent implements OnInit, OnChanges, OnDestroy {
  private readonly subscriptions: Subscription[] = [];

  public readonly zTableProp = zTableProp;

  @Input()
  public assignment: NodeModel;

  @Input()
  public project: NodeModel;

  public tableColumns: TableColumn[] = [];
  public tableBody: TableRow[] = [];

  public rateValues: NodeRateValueModel[] = [];

  public rates: NodeRateModel[] = [];
  public rateOptions: any[] = [];

  public groups: WorkspaceGroupModel[] = [];
  public groupOptions: any[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    private store: Store<AppState>,
    private modalService: NgbModal,
  ) {}

  public ngOnInit() {
    this.resetTable();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.assignment &&
      changes.assignment.previousValue?.id !== changes.assignment.currentValue?.id
    ) {
      this.resetTable();
      return;
    }
    if (changes.project && changes.project.previousValue?.id !== changes.project.currentValue?.id) {
      this.resetTable();
      return;
    }
    this.onTableUpdate();
  }

  public resetTable(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });

    this.subscriptions.push(
      this.store
        .pipe(select(WorkspaceGroupsSelectors.selectWorkspaceClaimPeriodGroups))
        .subscribe(groups => {
          this.groups = groups || [];
          this.groupOptions = [];
          this.groups.forEach(group => {
            this.groupOptions.push({
              value: group.id,
              label: group.title,
            });
          });
          this.onTableUpdate();
        }),
    );

    this.subscriptions.push(
      this.store
        .pipe(select(NodeRatesSelectors.selectNodeRatesByNodeId(this.project.id)))
        .subscribe(rates => {
          this.rates = rates || [];
          this.rateOptions = [];
          this.rates.forEach(rate => {
            this.rateOptions.push({
              value: +rate?.id,
              label: rate.title,
              type: RateType[rate.rateType],
              units: rate.units,
              amount: (rate.value || 0) / 100,
            });
          });
          this.onTableUpdate();
        }),
    );

    this.subscriptions.push(
      this.store
        .pipe(select(NodeRateValuesSelectors.selectNodeRateValuesByNodeId(this.assignment.id)))
        .subscribe(rateValues => {
          this.rateValues = rateValues || [];
          this.rates.forEach(rate => {
            // The initial state of this table should include all the Revenue items listed in the project properties
            // So check whether the items which is not in table and add that
            const index = this.rateValues.findIndex(r => r.nodeRateId === rate.id);
            if (index === -1) {
              const item: NodeRateValueModel = {
                assessed: 0,
                groupId: null,
                reportPeriod: '',
                id: null,
                nodeId: this.assignment.id,
                nodeRateId: rate?.id,
                note: '',
                quantity: 0,
                rate: null,
                rateType: rate?.rateType,
                units: rate?.units,
                value: rate?.value,
                readOnly: false,
                total: 0,
                revenueRecordStatus: '',
              };
              this.rateValues.push(item);
            }
          });

          this.onTableUpdate();
        }),
    );

    this.onTableColumns();
    this.onTableUpdate();
  }

  public onTableEdit(event: TableCellEditEvent) {
    if (event.cell.value === event.previousValue) {
      return;
    }

    // This is a new Row event
    if (event.row.id === zTableProp.EmptyRowId) {
      const props = {
        ...{
          nodeId: this.assignment.id,
          nodeRateId: null,
          reportPeriod: '',
          quantity: 0,
          revenueRecordStatus: '',
        },
        [event.field]: event.cell.value,
      };
      this.store.dispatch(NodeRateValuesActions.addNodeRateValueRequest(props));
      return;
    } else if (event.row.id === 'null') {

      //find the column index of the NodeRateId
      let nodeRateIdColumnIndex = 0;
      this.tableColumns.filter((item: any, index: number) => {
        if (item.id === 'nodeRateId') nodeRateIdColumnIndex = index;
      });

      console.log(nodeRateIdColumnIndex);

      // this item is only added to the table from the start.
      // now we are going to save that in the db.
      const props = {
        ...{
          nodeId: this.assignment.id,
          nodeRateId: event?.row?.columns[nodeRateIdColumnIndex]?.value, // This need to be updated if you ever change the order of columns. This nodeRateId is the id in WorkspaceNodeRateValues table
          reportPeriod: '',
          quantity: 0,
          revenueRecordStatus: '',
        },
        [event.field]: event.cell.value,
      };
      this.store.dispatch(NodeRateValuesActions.addNodeRateValueRequest(props));
      return;
    }
    // Existing row event
    this.store.dispatch(
      NodeRateValuesActions.updateNodeRateValueRequest({
        rateValueId: parseInt(event.row.id, 10),
        rateValueProps: {
          [event.field]: event.cell.value,
        },
      }),
    );
  }

  public onTableGroupMove(event: TableGroupMoveEvent) {
    return;
  }

  public onTableRowMove(event: TableRowMoveEvent) {
    return;
  }

  public onTableNewRow(event) {
    this.store.dispatch(
      NodeRateValuesActions.addNodeRateValueRequest({
        nodeId: this.assignment.id,
        nodeRateId: null,
        reportPeriod: '',
        quantity: 0,
        revenueRecordStatus: '',
      }),
    );
  }

  public onTableInsert(row: TableRow, index: number) {
    this.store.dispatch(
      NodeRateValuesActions.addNodeRateValueRequest({
        nodeId: this.assignment.id,
        nodeRateId: null,
        reportPeriod: '',
        quantity: 0,
        revenueRecordStatus: '',
      }),
    );
  }

  public onTableCopy(row: TableRow, index: number) {
    const rateValue = this.rateValues.find(r => r.id.toString() === row.id);
    if (rateValue == null) {
      throw new Error('Rate Value not found.');
    }
    this.store.dispatch(
      NodeRateValuesActions.addNodeRateValueRequest({
        nodeId: this.assignment.id,
        nodeRateId: rateValue.nodeRateId,
        reportPeriod: rateValue.reportPeriod,
        quantity: rateValue.quantity,
        revenueRecordStatus: rateValue.revenueRecordStatus,
      }),
    );
  }

  public onTableDelete(row: TableRow, index: number) {
    const modelRef = this.modalService.open(ConfirmPopupComponent, {
      size: 'md',
      backdrop: 'static',
    });
    modelRef.componentInstance.title = 'Delete Rate';
    modelRef.componentInstance.message = 'Do you want to Delete this Rate?';
    modelRef.result
      .then((result?: boolean) => {
        if (result) {
          this.store.dispatch(
            NodeRateValuesActions.deleteNodeRateValueRequest({
              nodeId: this.assignment.id,
              rateValueId: parseInt(row.id, 10),
            }),
          );
        }
      })
      .catch(res => {});
  }

  public onTableColumns() {
    const reportStatusOptions = RevenueRecordStatusTypeOptions.map(rst => {
      return {
        value: rst,
        label: RevenueRecordStatusType[rst],
      };
    });

    this.tableColumns = [
      {
        id: 'reportPeriod',
        label: 'Report Period',
        colSpan: 3,
        sortable: true,
        editor: TableEditor.MonthYear,
        editable: true,
        collapsed: false,
      } as TableColumn,
      {
        id: 'rateType',
        label: 'Rate Type',
        colSpan: 2,
        sortable: true,
        collapsed: false,
        alignment: TableColumnAlignment.Left,
        editor: TableEditor.Text,
        editable: false,
        groupable: true,
      } as TableColumn,
      // THE POSITION OF COLUMN NODERATEID IMPACTS THE ADDING OF VALUES
      // CHECK onTableEdit for more info
      {
        id: 'nodeRateId',
        label: 'Item',
        colSpan: 6,
        sortable: true,
        editor: TableEditor.WorkspaceRate,
        editable: false,
        groupable: true,
        groupLabel: (id: string): string => {
          const rate = this.rates.find(r => String(r.id) === String(id));
          return rate ? rate.title : '';
        },
      } as TableColumn,
      {
        id: 'value',
        label: 'Rate ($)',
        colSpan: 3,
        sortable: true,
        alignment: TableColumnAlignment.Right,
        groupable: true,
        editor: TableEditor.Number,
        editable: false,
        options: {
          decimals: 2,
          min: -99999999.99,
          max: 99999999.99,
        },
      } as TableColumn,
      {
        id: 'units',
        label: 'Units',
        colSpan: 2,
        sortable: true,
        groupable: true,
        alignment: TableColumnAlignment.Right,
        editor: TableEditor.Text,
        editable: false,
      } as TableColumn,
      {
        id: 'quantity',
        label: 'Daily Qty',
        colSpan: 2,
        sortable: true,
        alignment: TableColumnAlignment.Right,
        groupable: true,
        editor: TableEditor.Number,
        editable: true,
      } as TableColumn,
      {
        id: 'total',
        label: 'Daily Total',
        colSpan: 3,
        sortable: true,
        alignment: TableColumnAlignment.Right,
        groupable: true,
        editor: TableEditor.Number,
        editable: false,
        options: {
          decimals: 2,
        },
        includeTotal: true,
      } as TableColumn,
      {
        id: 'assessed',
        label: 'Assessed',
        colSpan: 3,
        sortable: true,
        alignment: TableColumnAlignment.Right,
        groupable: true,
        editor: TableEditor.Number,
        editable: true,
        options: {
          decimals: 2,
        },
        includeTotal: true,
        collapsed: true,
      } as TableColumn,
      {
        id: 'difference',
        label: 'Difference',
        colSpan: 3,
        sortable: true,
        alignment: TableColumnAlignment.Right,
        groupable: true,
        editor: TableEditor.Number,
        editable: false,
        options: {
          decimals: 2,
        },
        includeTotal: true,
        collapsed: true,
      } as TableColumn,
      {
        id: 'note',
        label: 'Reference',
        colSpan: 4,
        sortable: true,
        editor: TableEditor.Textarea,
        editable: true,
        collapsed: true,
        options: {
          maxlength: 1024,
        },
      } as TableColumn,
      {
        id: 'revenueRecordStatus',
        label: 'Record Status',
        colSpan: 4,
        sortable: true,
        editor: TableEditor.Select,
        editable: true,
        collapsed: true,
        options: {
          list: reportStatusOptions,
        },
      } as TableColumn,
    ];
  }

  public onTableUpdate() {
    this.tableBody = this.rateValues.map(rateValue => {
      const total = rateValue.quantity * ((rateValue.value || 0) / 100);
      return {
        id: String(rateValue.id),
        columns: [
          {
            value: rateValue.reportPeriod,
            alignment: TableColumnAlignment.Left,
            options: {
              assignment: this.assignment,
            },
          } as TableCell,
          {
            value: RateType[rateValue.rateType] || '',
            alignment: TableColumnAlignment.Left,
          } as TableCell,
          {
            value: rateValue.nodeRateId,
            alignment: TableColumnAlignment.Right,
            options: {
              list: this.rateOptions,
            },
          } as TableCell,
          {
            value: (rateValue.value || 0) / 100,
            alignment: TableColumnAlignment.Right,
          } as TableCell,
          {
            value: rateValue.units,
            alignment: TableColumnAlignment.Right,
          } as TableCell,
          {
            value: rateValue.quantity,
            alignment: TableColumnAlignment.Right,
          } as TableCell,
          {
            value: total,
            alignment: TableColumnAlignment.Right,
          } as TableCell,
          {
            value: rateValue.assessed,
            alignment: TableColumnAlignment.Right,
          } as TableCell,
          {
            value: total - rateValue.assessed,
            alignment: TableColumnAlignment.Right,
          } as TableCell,
          {
            value: rateValue.note,
            alignment: TableColumnAlignment.Left,
          } as TableCell,
          {
            value: rateValue.revenueRecordStatus,
            alignment: TableColumnAlignment.Center,
          } as TableCell,
        ],
      };
    });
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => {
      s.unsubscribe();
    });
  }
}
